.labeled-input.table-style {
    display: flex;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
}

.datePicker {
    font-size: .9rem !important;
}

.labeled-input.table-style label {
    text-align: right;
    width: 13rem;
    flex-shrink: 0;
    margin: 0;
    padding-right: 1rem;
}

#customer-delivery-details {
    max-width: calc(100vw);
    height: calc(100vh);
    display: flex;
    flex-flow: column;
    overflow: hidden;
    margin: 0px;
}

#customer-delivery-details .modal-header {
    padding: .5rem;
}

#customer-delivery-details .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
}

#customer-delivery-details .modal-body {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    overflow: hidden;
}

#customer-delivery-details .modal-body > div.data {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    flex-basis: 70%;
    flex-shrink: 0;
}

#customer-delivery-details .pagination {
    display: none !important;
}

#customer-delivery-details .datePicker ._3RtEX {
    position: fixed;
    z-index: 1000;
}

#customer-delivery-details .modal-body .data > div > div {
    overflow-y: auto;
}

#customer-delivery-details .labeled-input.table-style label {
    width: 11rem;
}

#customer-delivery-details .modal-body > div.table {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    position: relative;
    min-height: 8rem;
}

#customer-delivery-details .modal-body > div.table .tableWrap {
    height: 100%;
    overflow-y: auto;
}

#customer-delivery-details .modal-body > div.table .tableWrap table thead {
    position: sticky;
    top: -1px;
    left: 0px;
}

#customer-delivery-details .tableWrap thead tr {
    background-color: white;
  }

#customer-delivery-details .modal-body > div.data > * {
    flex-basis: 40%;
    padding: 0;
}
#customer-delivery-details .modal-body > div > #date,
#customer-delivery-details .modal-body > div > #payment_method,
#customer-delivery-details .modal-body > div > #email,
#customer-delivery-details .modal-body > div > #search,
#customer-delivery-details .modal-body > div > #address {
    flex-basis: 80%;
    justify-content: center;
}

#customer-delivery-details .modal-body .data > div > div,
#customer-delivery-details .modal-body .data > div > div > button,
#customer-delivery-details .modal-body > div input {
    width: 100%;
}

#customer-delivery-details .modal-body > div .datePicker input {
    text-align: right;
    padding-right: 0.5rem;
    width: 15rem;
    min-width: unset;
    background: unset;
}

#customer-delivery-details .modal-body > div .timePicker input {
    text-align: left;
    width: 6rem;
    background: unset;
}

#customer-delivery-details .moday-body > table {
    font-size: 1.2rem
}
#customer-delivery-details table thead {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}
#customer-delivery-details .timePicker input,
#customer-delivery-details .datePicker input {
    font-size: 1.5rem;
    font-family: "Mohave";
}
#customer-delivery-details .timePicker > input + div,
#customer-delivery-details .datePicker > input + div {
    z-index: 10000
}

#customer-delivery-details .datePicker input + div > div > div > div > div:nth-child(2) > div:nth-child(1) {
    display: none;
}

#customer-delivery-details .leaflet-marker-icon.selectedAddress {
    filter: drop-shadow(1px 1px 4px black);
}

#customer-delivery-details .leaflet-tooltip.selectedAddress {
    border: 1px solid blue;
    filter: drop-shadow(1px 1px 2px black);
    background-color:coral;
    color: white;
}

#customer-delivery-details .leaflet-tooltip:hover {
    filter: drop-shadow(2px 2px 1px black);
    background: chocolate;
    color: white;
}
#customer-delivery-details .leaflet-tooltip {
    border-radius: 5px;
    filter: drop-shadow(3px 3px 4px black);
    pointer-events: visiblePainted;
}

#customer-delivery-details .leaflet-pane.leaflet-tooltip-pane {
    z-index: 1000;
    width: 100%;
    height: 100%;
}

#customer-delivery-details #mapContainer {
    display: flex;
    flex-flow: row;
    flex-basis: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
}

#customer-delivery-details #addresses {
    padding-left: 10px;
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 1000;
    max-height: 30%;
    height: -webkit-fill-available;
    max-width: 100%;
}

#customer-delivery-details #addresses tr {
    display: flex;
}

#customer-delivery-details #addresses tr button {
    padding: 0;
    height: 1.8rem;
    border: unset;
    border-radius: unset;
    width: 100%;
}

#customer-delivery-details #addresses .tableWrap {
    height: 100%;
    position: relative;
    overflow: hidden;
}

#customer-delivery-details #addresses .tableWrap table {
    height: 100%;
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    max-width: 100%;
    display: block;
}

#customer-delivery-details #addresses tbody {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
}

#customer-delivery-details #addresses thead {
    display: none;
}

#customer-delivery-details #addresses tr {
    overflow: visible;
    background: white;
    box-shadow: 2px 2px 4px black;
    width: fit-content;
    border-radius: 10px;
    border: 1px solid gray;
    gap: 0;
}

#customer-delivery-details #addresses th,
#customer-delivery-details #addresses td {
    font-size: 1rem;
    width: fit-content;
    display: block;
    margin: 3px ;
    padding: 0;
    overflow: visible;
}

#customer-delivery-details #addresses td:nth-child(1) {
    padding-left: 5px;
    padding-right: 5px;
    border: unset;
    margin: 0;
    line-height: 1.8rem;
}
#customer-delivery-details #addresses td:nth-child(2) {
    margin: 0;
}

#customer-delivery-details #addresses td:hover {
    background: chocolate;
    color: white;   
    box-shadow: 1px 1px 2px black !important;
}