#dailyClosing span.fancyTree-title,
#dailyClosing .fancytree-exp-n span.fancytree-expander,
#dailyClosing .fancytree-exp-nl span.fancytree-expander {
    display: none
}

#dailyClosing th.first,
#dailyClosing td.first {
    display: none
}

#dailyClosing td.childOrder {
    font-size: 1rem;
}

#dailyClosing tr.fancytree-hide {
    opacity: .5
}

.gj-datepicker-bootstrap [role=right-icon] button .gj-icon {
    font-size: 2rem;
    position: unset;
}

#dailyClosing.col-form-label,
#dailyClosing.form-control,
#dailyClosing table,
#dailyClosing body {
    font-size: 1rem !important;
}

#dailyClosing th {
    padding: unset;
}

#dailyClosing .bar h1 {
    font-size: 30px;
}

#dailyClosing i.fa.fa-calendar {
    font-family: "FontAwesome";
    background-image: unset;
    width: 2rem;
}

#dailyClosing .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

#dailyClosing #menuitem_labels + div > ul {
    overflow:hidden;
    min-height:unset;
}
#dailyClosing #menuitem_labels + div {
    height: 2.2rem;
    margin:0;
}
#dailyClosing #menuitem_labels + div > ul > li {
    margin: .2rem .2rem 0 0;
}

@media (max-width:800px) {
    #dailyClosing .range-bar {
        font-size: 1.7rem;
    }
}

@media (max-width:887px) {
    #dailyClosing .range-bar {
        font-size: 1.7rem;
    }
    .card .tab-pane,
    .card .tab-pane > *,
    .card {
        height: unset !important;
        overflow: visible !important;
        max-height: unset !important;
    }
}