#profitandloss {
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
}

#profitandloss > div {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
}

#profitandloss > div .tabContent {
  flex-grow: 1;
  overflow: hidden;
}

#profitandloss > div .tabContent > div,
#profitandloss .table > div {
  height: 100%;
  overflow: auto;
}

#profitandloss .table {
  flex-grow: 10000;
  flex-shrink: 1;
  background-color: white;
  margin: 0;
  overflow: hidden;
  position: relative;
  height: 100%;
}

#profitandloss .reflex-container > .reflex-element {
  overflow: hidden;
}

#profitandloss .reflex-container > .reflex-element > .pane-content {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
}

#profitandloss .reflex-container > .reflex-element > .pane-content > div {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}

#profitandloss .reflex-container > .reflex-element > .pane-content > div .table > div {
  overflow: hidden;
  height: 100%;
}

#profitandloss .cp_tree-table_viewport {
  height: calc(100% - 26px) !important;
}

#profitandloss .horizontal > .reflex-splitter {
  border-bottom: 4px solid #c6c6c6;
  border-top: 4px solid #c6c6c6;
  cursor: row-resize;
  width: 100%;
  height: 2px;
}

#profitandloss .tableName {
  display: flex;
  justify-content: center;
  font-size: 1.3em;
  font-weight: bold;
  flex-grow: 1;
}

.cp_tree-table {
  font-size: 14px;
  color: #040402;
  background: #fff;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  width: fit-content !important;
  min-width: 100%;
  top: 0;
  overflow: auto;
  height: 100%;
}

/* Header */

.cp_tree-table .cp_tree-table_header {
  color: rgba(55, 53, 47, 0.6);
  background: rgba(242, 241, 238, 0.6);
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

.cp_tree-table_viewport {
  max-height: 100%;
}

/* Employees & Expenses */

.cp_tree-table .cp_tree-table_header-cell > .t-right {
  width: 100%;
  text-align: right;
}

/* Cells */

.cp_tree-table .cp_tree-table_cell,
.cp_tree-table .cp_tree-table_header-cell {
  display: flex;
  align-items: center;
  padding: 0 10px;
  white-space: nowrap;
  border: 1px solid lightgray;
  min-width: 100px;
}

.cp_tree-table .cp_tree-table_cell:nth-child(1),
.cp_tree-table .cp_tree-table_header-cell:nth-child(1) {
  min-width: 200px;
  white-space: normal;
}

.cp_tree-table .cp_tree-table_cell:nth-child(2),
.cp_tree-table .cp_tree-table_header-cell:nth-child(2) {
  min-width: 150px;
  white-space: normal;
}

/* Editable cell */

.cp_tree-table .cp_tree-table_cell > input {
  font-size: 14px;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 5px 8px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px rgba(55, 53, 47, 0.16);
  border: 1px solid transparent;
}

.cp_tree-table .cp_tree-table_cell > input:hover {
  box-shadow: inset 0 0 0 1px #0f55eb;
}

.cp_tree-table .cp_tree-table_cell > input:focus {
  background: #edf6fc;
  box-shadow: inset 0 0 0 1px #0f55eb;
}

/* Index cell  */

.cp_tree-table .cp_tree-table_cell .toggle-button {
  position: relative;
  display: inline-block;
  border: none;
  height: 14px;
  vertical-align: middle;
  padding: 0 5px 0 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.cp_tree-table .cp_tree-table_cell .toggle-button::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  transform: rotate(-90deg);
  border-color: #040402 transparent transparent transparent;
  padding: 0;
  vertical-align: text-top;
}

.cp_tree-table .cp_tree-table_cell .toggle-button.expanded::after {
  transform: unset;
}

.cp_tree-table .cp_tree-table_cell .toggle-button + span {
  vertical-align: middle;
}

.cp_tree-table .cp_tree-table_cell > .without-children > span {
  padding-left: 15px;
}

.cp_tree-table .cp_tree-table_cell > .with-children > span {
  font-weight: 600;
}

/* Employees cell */

.cp_tree-table .cp_tree-table_cell > .employees-cell {
  width: 100%;
  text-align: right;
}

/* Expenses cell */

.cp_tree-table .cp_tree-table_cell > .expenses-cell {
  font-family: "SF Mono", "Segoe UI Mono", "Roboto Mono", Menlo, Courier, monospace;
  font-weight: 400;
  width: 100%;
  text-align: right;
  padding-right: 10px;
}

/* Buttons */
.controls {
  padding: 8px 0;
}

.controls button {
  appearance: none;
  background: rgba(242, 241, 238, 1);
  border: none;
  border-radius: 3px;
  margin-right: 16px;

  color: #37352f;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 10px;
  outline: none;
}

.controls button:hover {
  background: #edf6fc;
  color: #0f55eb;
}
