/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

html {
  touch-action: none;
  -ms-touch-action: none;
  -ms-content-zooming: none;
  height: 100vh;
  overflow: hidden;
}

.topBar {
  width: 100%;
  display: flex;
  flexShrink: 0;
  alignItems: center;
  flexFlow: column
}

.topBar .dropdown {
  max-width: calc(100% - 150px);
  overflow: hidden;
}

.eatwithme-splash-background {
  position: fixed;
  content: url("../img/EwM-customer-logo.svg");
  width: 70vmin;
  height: auto;
  max-width: 50%;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .03;
}

.draggable {
  touch-action: none;
  -ms-touch-action: none;
  -ms-content-zooming: none;
}

p {
  font-size: unset;
}
.dropdown-menu {
  font-size: unset;
}

body {
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
  font-family: "teko_regularregular";
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  position: fixed;
  display: flex;
  flex-flow: column;
}

body.test-mode {
  height: calc(100%);
  left: 0;
  right: 0;
}
body.test-mode > #main {
}

@media (orientation: portrait) {
  body[device="iOS"] {
    /*height:calc(100vh - 20px);*/
  }
  html[device="iOS"] {
    position: absolute;
    height: calc(100vh);
    top: 0;
  }
  body[device="iOS"] .loginDialog {
    /*height:calc(100vh - 20px);*/
  }

  body.test-mode[device="iOS"] {
  }
  body[device="iOS"] > #main {
  }
  body.test-mode[device="iOS"] > #main {
  }
}
body > #test_mode.top {
  width: 100%;
  text-align: center;
  background: orange;
  font-size: 12px;
  height: 18px;
  -webkit-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse-background 5.25s infinite cubic-bezier(0.66, 10, 0, 1);
}
body > #test_mode.bottom {
  top: 100%;
  width: 100%;
  text-align: center;
  background: orange;
  height: 18px;
  font-size: 12px;
  -webkit-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse-background 5.25s infinite cubic-bezier(0.66, 10, 0, 1);
}

/* Portrait layout (default) */
.app {
  background: url("../img/logo.png") no-repeat center top; /* 12.1rem x 200px */
  position: absolute; /* position in the center of the screen */
  left: 50%;
  top: 50%;
  height: 2rem; /* text area height */
  width: 22.2rem; /* text area width */
  text-align: center;
  padding: 12rem 0 0; /* image height is 200px (bottom 1.42rem are overlapped with text) */
  margin: -11.2rem 0 0 -8rem; /* offset vertical: half of image height and text area height */
  /* offset horizontal: half of text area width */
}

.card {
  border: 0.05rem solid rgba(0, 0, 0, 0.125);
}

.list-group-item {
  border: 0.05rem solid rgba(0, 0, 0, 0.125);
}

.modal-content {
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.modal-header {
  border-bottom: 0.05rem solid #e9ecef;
  align-items: center;
}

input[readonly]:focus {
  animation: readonlyinput 500ms infinite;
  -webkit-animation: readonlyinput 500ms infinite;
  -moz-animation: readonlyinput 500ms infinite;
  -ms-animation: readonlyinput 500ms infinite;
}

@-webkit-keyframes readonlyinput {
  from {
    border-bottom: 1px solid black;
  }
  50% {
    border-bottom: 1px solid gray;
  }
  to {
    border-bottom: 1px solid black;
  }
}
@-moz-keyframes readonlyinput {
  from {
    border-bottom: 1px solid black;
  }
  50% {
    border-bottom: 1px solid gray;
  }
  to {
    border-bottom: 1px solid black;
  }
}
@-ms-keyframes readonlyinput {
  from {
    border-bottom: 1px solid black;
  }
  50% {
    border-bottom: 1px solid gray;
  }
  to {
    border-bottom: 1px solid black;
  }
}
@keyframes readonlyinput {
  from {
    border-bottom: 1px solid black;
  }
  50% {
    border-bottom: 1px solid gray;
  }
  to {
    border-bottom: 1px solid black;
  }
}

.ui-resizable-e {
  cursor: e-resize;
  width: 1rem;
  right: 0;
  top: 0;
  height: 100%;
  border-right: 0.1rem dashed gray;
  z-index: 0 !important;
}

.ui-resizable-w {
  cursor: e-resize;
  width: 2rem;
  right: 0;
  top: 0;
  height: 100%;
}

.overview .ui-resizable-s {
  cursor: s-resize;
  height: 2rem;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.1rem dashed gray;
}

.overview .ui-resizable-n {
  cursor: s-resize;
  height: 1rem;
  top: 0;
  left: 0;
  width: 100%;
}

.scrollable {
  overflow: auto;
}

.scrollable-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.scrollable-y {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.scrollable.modal-open,
.scrollable-x.modal-open,
.scrollable-y.modal-open {
  overflow: hidden;
}

/* Landscape layout (with min-width) */
@media screen and(min-aspect-ratio: 1 / 1) and(min-width: 400px) {
  .app {
    background-position: left center;
    padding: 7.2rem 0 7.2rem 12.1rem; /* padding-top + padding-bottom + text area = image height */
    margin: -6.42rem 0 0 -14.14rem; /* offset vertical: half of image height */
    /* offset horizontal: half of image width and text area width */
  }
}

h1 {
  font-size: 2rem;
  font-weight: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: center;
}

.event {
  border-radius: 0.28rem;
  -webkit-border-radius: 0.28rem;
  color: #ffffff;
  font-size: 0.85rem;
  margin: 0 2.14rem;
  padding: 0.14rem 0;
}

.event.listening {
  background-color: #333333;
  display: block;
}

.event.received {
  background-color: #4b946a;
  display: none;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 3000ms infinite;
  -webkit-animation: fade 3000ms infinite;
}

.goalTable {
  background: rgba(245, 245, 245, 0.9);
  width: 100%;
}

.icon-social.icon-facebook {
  border: 1px solid #578ee8 !important;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 5px !important;
}

.icon-facebook:before {
  content: "";
  height: 25px;
  width: 25px !important;
  min-width: unset !important;
  color: white;
  background-color: #578ee8 !important;
  background-position: 50% 150%;
  background-image: url("../img/fb.png");
  background-size: auto 88%;
  background-repeat: no-repeat;
  border-radius: 100%;
  margin-right: 6px !important;

  text-align: right;
}

.icon-apple {
  height: 40px !important;
  background-position: 0 50%;
  background-image: url("../img/appleid_button@4x.png");
  background-size: 100%;
  padding: 0;
  border: none;
  background-color: unset;
}

.modal-dialog[lang="hu"] .icon-apple {
  background-image: url("../img/appleid_button@4x-hu.png");
}

.icon-twitter {
  background: #5dc2e6;
  background-position: 0.71rem 50%;
  background-image: url("../img/tw.png");
  padding-left: 2.85rem;
}

.icon-google {
  background: #f45c37;
  background-position: 0.71rem 50%;
  background-image: url("../img/gp.png");
  padding-left: 2.85rem;
}

.icon-linkedin {
  background: #0274b3;
  background-position: 0.71rem 50%;
  background-image: url("../img/ln.png");
  padding-left: 2.85rem;
}

.icon-social {
  background-repeat: no-repeat;
  /*background-color: #ccc;*/
  text-align: center;
  color: white;
  font-size: 15px !important;
  width: 250px;
  height: 38px;
  padding: 10px 3px 10px 40px !important;
  text-align: left;
  line-height: 18px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5px;
  border: unset;
}

.icon-add-table {
  background-position: 0 50%;
  background-size: 3rem 3rem;
  background-image: url("../img/add_64.png");
  background-repeat: no-repeat;
  margin-right: 1rem;
  height: 3rem;
  width: 3.2rem;
}

.icon-change-table {
  background-position: 0 50%;
  background-size: 3rem 3rem;
  background-image: url("../img/change-table-64.png");
  background-repeat: no-repeat;
  margin-right: 1rem;
  height: 3rem;
  width: 3.2rem;
}

.icon-table {
  font-family: "HelveticaNeue-Light", "HelveticaNeue", Helvetica, Arial, sans-serif;
  background-image: url("../img/restaurant-table-50.png");
  background-repeat: no-repeat;
  /*background-color: #ccc;*/
  background-position: 0.71rem 50%;
  text-align: center;
  padding-right: 0.2rem;
  padding-left: 3.2rem;
  color: white;
  height: 2rem;
  font-size: 1.57rem;
}

button.icon-join-table {
  font-family: "HelveticaNeue-Light", "HelveticaNeue", Helvetica, Arial, sans-serif;
  background-image: url("../img/joining-table-50.png");
  background-repeat: no-repeat;
  /*background-color: #ccc;*/
  background-position: 0.71rem 50%;
  text-align: center;
  padding-right: 0.2rem;
  padding-left: 3.2rem;
  color: white;
  height: 2rem;
  font-size: 1.57rem;
}

button.icon-add {
  font-family: "HelveticaNeue-Light", "HelveticaNeue", Helvetica, Arial, sans-serif;
  background-image: url("../img/friedn_added-20.png");
  background-repeat: no-repeat;
  /*background-color: #ccc;*/
  background-position: 0.2rem 50%;
  text-align: center;
  padding-right: 0.2rem;
  padding-left: 2rem;
  color: white;
  height: 2rem;
  font-size: 0.85rem;
}

button.scanToJointable,
button.scanToChangeTable {
  font-family: "HelveticaNeue-Light", "HelveticaNeue", Helvetica, Arial, sans-serif;
  background-image: url("../img/qr.png");
  background-repeat: no-repeat;
  /*background-color: #ccc;*/
  background-position: 0.71rem 50%;
  text-align: center;
  padding-right: 0.2rem;
  padding-left: 3.2rem;
  color: white;
  height: 2rem;
  font-size: 1.57rem;
}

.dp-numberPicker,
.dp-numberPicker-add,
.dp-numberPicker-sub,
.dp-numberPicker-input {
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  vertical-align: top;
  font-family: "Roboto", serif;
}

.dp-numberPicker {
  box-shadow: 0 0 0.2rem rgba(255, 255, 255, 0.5);
  border-radius: 0.21rem;
}

.dp-numberPicker-add,
.dp-numberPicker-sub {
  width: 2rem;
  font-size: 2.2rem;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  background-color: #33b5e5;
  color: white;
  font-weight: 700;
  text-shadow: 0.05rem.05rem.05rem rgba(50, 50, 50, 0.5);
}

.dp-numberPicker-add {
  border-top-right-radius: 0.21rem;
  border-bottom-right-radius: 0.21rem;
  box-shadow: inset 0rem.05rem.05rem rgba(255, 255, 255, 0.5), inset -0.07rem -0.07rem.05rem rgba(0, 0, 0, 0.5);
}

.dp-numberPicker-sub {
  border-top-left-radius: 0.21rem;
  border-bottom-left-radius: 0.21rem;
  box-shadow: inset.05rem.05rem.05rem rgba(255, 255, 255, 0.5), inset 0 -0.07rem.05rem rgba(0, 0, 0, 0.5);
}

.dp-numberPicker-sub.disabled,
.dp-numberPicker-add.disabled {
  background-color: #2c6a81;
}

.dp-numberPicker-input {
  width: 7.14rem;
  font-size: 0.8rem;
  background-color: #232425;
  color: white;
  border: 0;
  box-shadow: inset 0rem.05rem.05rem rgba(255, 255, 255, 0.3), inset 0 -0.07rem.05rem rgba(0, 0, 0, 0.5);
}

.dp-numberPicker-input:disabled {
  background-color: #232425;
}

.badge {
  display: flex;
  padding: 0.3rem 0.45rem 0.15rem;
  font-size: 1rem;
  line-height: 1.2rem;
  border-radius: 1rem;
  white-space: normal;
  align-items: center;
}
.badge.badge-inverted {
  padding: 0 0.2rem 0 0;
  background-color: transparent;
}

.badge-primary {
  background-color: #fff;
  color: #428bca;
  border-color: #428bca;
  border-width: 1px;
  border-style: solid;
}
.badge-primary.badge-inverted {
  color: #428bca;
}

.badge-positive {
  color: #fff;
  background-color: #5cb85c;
}
.badge-positive.badge-inverted {
  color: #5cb85c;
}

.badge-negative {
  color: #fff;
  background-color: #d9534f;
}

.badge-person {
  color: #fff;
  border: 0.05rem #0ccc solid;
  background-color: #ccbc0c;
  left: -0.4rem;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  height: 1.6rem;
  width: fit-content;
}

.badge-person.inactive {
  background-color: gray;
}

.badge.icon-warning {
  background-image: url("../img/warning.png");
  background-repeat: no-repeat;
  background-position: -0.2rem center;
  background-color: #ccbc0c;
  padding-right: 1rem;
}

.btn.icon-camera {
  color: white;
}

img.person {
  border-radius: 100%;
  left: 0;
  position: relative;
  left: -0.3rem;
  width: 1.4rem;
  height: 1.4rem;
  margin: -0.16rem 0 0;
}

.paying-customer.customer > div.persons-stack {
  margin-top: -0.8rem;
  width: 100%;
  display: flex;
  z-index: 20;
}

/*
.paying-customer.customer.single-customer li.customer {
	margin-top: -1.1rem;
	margin-left: -2rem;
	background: unset;
	border:unset;
}
*/

div.persons-stack {
  position: relative;
  float: left;
  margin-right: 0.4rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  height: 1rem;
  vertical-align: middle;
}

abbr {
  vertical-align: middle;
}

img.warning {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  border-radius: 100%;
  padding-left: 0;
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 0.07rem;
  right: 0.07rem;
  display: inline;
}

.btn.icon-photos {
  background: 50% 50% #428bca url("../img/photos-24.png") no-repeat;
  width: 3.28rem;
  height: 100%;
  background-size: 1.7rem 1.7rem;
}

.icon {
  display: block;
}

.icon.icon-addlogin {
  background: url("../img/inside-logout-32.png") no-repeat;
  background-size: 3rem 3rem;
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.icon.icon-nav-back {
  background: url("../img/arrow-back-24.png") no-repeat;
  background-size: 3rem 3rem;
  width: 3rem;
  height: 3rem;
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.eatwithme-input-group-btn {
  border-collapse: separate;
  display: table-cell;
  font-size: 0;
  position: relative;
  line-height: 0;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.eatwithme-input-group {
  display: table;
  border-collapse: separate;
  position: relative;
  box-sizing: boder-box;
  background-color: lightgray;
  border: 0 solid transparent;
  border-radius: 0.28rem;
}

.eatwithme-btn {
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 2.42rem;
  width: 1.4rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 0.05rem solid transparent;
  border-radius: 0.28rem;
}

.tokenize > .tokens-container > .token.approved {
  background-color: #5cb85c;
}

.cards {
  background-color: #fff;
}

/* CSS REQUIRED */
.state-icon {
  left: -0.2rem;
}
.list-group-item-primary {
  color: rgb(255, 255, 255);
  background-color: rgb(66, 139, 202);
}

/* DEMO ONLY - REMOVES UNWANTED MARGIN */
.well .list-group {
  margin-bottom: 0;
}

.mateName {
  width: 6.42rem;
  min-width: 6.42rem;
  overflow: hidden;
  position: absolute;
  vertical-align: top;
}

.footer {
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 2.42rem;
  width: 100%;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 0.05rem solid transparent;
  border-radius: 0.28rem;
}

.bar-expanding {
  height: unset;
  display: inline-block;
  margin: 0;
  padding-bottom: 0.78rem;
  position: relative;
  width: 100%;
}

.bar-expanding > .icon {
  height: 1rem;
}

.bar-nav ~ .content {
  position: static;
  padding-top: 0.2rem;
}

.smallImage {
  width: 1.85rem;
  padding-right: 0.2rem;
  padding-left: 0;
}

#smallImage[src=""] {
  display: none;
}

.mediumImage {
  width: 3.42rem;
  padding-right: 0.2rem;
  padding-left: 0;
}

.nocaret::after {
  content: unset;
}

.table-view .media,
.table-view .media-body {
  overflow: unset;
}

.icon-meals:before {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  content: url("../img/food-32.png");
  /*filter: invert();*/
  width: 40px;
}

.icon-meals,
.icon-drinks {
  width: 46px !important;
  height: 43px !important;
  position: relative;
}

.icon-drinks:before {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  content: url("../img/drink-32.png");
  /*filter: invert();*/
  width: 40px;
}

.input-row1 {
  font-weight: bold;
  font-size: medium;
  color: #428bca;
  padding-top: 1.2rem;
  padding-bottom: 0;
}

.btn-toolbar {
  padding-left: 10px;
  padding-right: 10px;
}

tr.itemDeactivated {
  color: gray;
}

td.itemDeactivated {
  color: gray;
}

tr.deleted td.name span.fancytree-title,
td.itemDeactivated span.fancytree-title {
  color: gray;
}

tr.deleted td.name {
  text-decoration: line-through;
}

table.fancytree-container tr.itemDeactivated {
  color: gray;
}

.fancytree-ext-filter-hide tr.itemDeactivated.fancytree-match span.fancytree-title,
table.fancytree-container tr.itemDeactivated span {
  color: gray;
}

table.fancytree-container tr.itemWarning {
  background-color: #e0ffe0;
}

table.fancytree-container td.itemWarning {
  background-color: #e0ffe0;
}

table.fancytree-container tr.itemShareable {
  background-color: #ffe0e0;
}

.hidden {
  display: none !important;
}

.hidden2 {
  visibility: hidden;
}

.customer-droppable.hidden3 {
  visibility: hidden;
  padding: 0;
  margin: 0 0.71rem;
  border: white 0 solid;
  height: 0;
}

.timing-droppable,
.customer-droppable.alwaysVisible,
.customer-droppable.shown {
  /*border: 0.05rem dotted;*/
  margin-top:2px !important;
  margin-bottom: 2px !important;
}

div.byCustomer,
div.byTiming {
  margin: 0.21rem;
  overflow: unset;
}

div#byCustomer,
div#byTiming {
  overflow: unset;
}

div.byTiming.menuItem-order {
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  left: 0.14rem;
  top: 0.033rem;
}

div.byTiming.menuItem-order.hidden {
  display: none;
}

div.state {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: -1.2rem;
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  top: 1rem;
  opacity: 0.5;
}

div.stateTransition {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: -1.5rem;
  background-repeat: no-repeat;
  background-position: 0 0.1rem;
  background-image: url("../img/forward-24.png");
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

div.previousState {
  opacity: 0.5;
  width: 0.8rem;
  height: 0.8rem;
  background-size: 0.8rem 0.8rem;
  position: absolute;
  left: -1.6rem;
  z-index: 99;
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%);
}

div.state.state_cancelled {
  background-image: url("../img/cancelled-24.png");
}

div.state.state_ordered {
  background-image: url("../img/new-24.png");
}

div.state.state_accepted {
  background-image: url("../img/accepted-24.png");
}

div.state.state_producing {
  background-image: url("../img/producing-24.png");
}

div.state.state_delivering {
  background-image: url("../img/delivering-64.png");
}

div.state.state_delivered {
  background-image: url("../img/delivered-24.png");
}

div.previousState.state_ordered {
  background-image: url("../img/new-24.png");
}

div.previousState.state_accepted {
  background-image: url("../img/accepted-24.png");
}

div.previousState.state_producing {
  background-image: url("../img/producing-24.png");
}

div.previousState.state_delivering {
  background-image: url("../img/delivering-24.png");
}

div.previousState.state_delivered {
  background-image: url("../img/delivered-24.png");
}

.byCustomer.draggable.state_ordered.highlight {
  border-color: #222;
}

.menuItem-name1,
.menuItem-timing1 {
  font-size: 1rem;
  min-height: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

img.tableMate {
  border-radius: 50%;
  height: 10vmin;
  width: 10vmin;
}

img.tableMate.orderedBy {
  border-radius: 50%;
  height: 1.3rem;
  width: 1.3rem;
  padding: 0;
  margin-right: 0.5rem;
  margin-top: -0.2rem;
}

div.tableMate.orderedBy {
  border-radius: 50%;
  height: 1rem;
  position: absolute;
  left: -1.2rem;
}

.orderCategoryImage,
.drinkOrderCategoryImage {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.orderTrashContainer {
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 1rem;
  right: 0;
  border-radius: 50%;
  z-index: 100;
  display: none;
}

.orderTrash {
  background: #ccc;
  visibility: hidden;
  background-image: url("../img/trash-32.png");
  background-size: 3rem 3rem;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: relative;
  top: 0;
  right: 0;
  background-position: 50% 50%;
  height: 100%;
  display: flex;
}

.orderTrash.ui-state-active2 {
  visibility: unset;
  background-color: #aaa;
  z-index: 990;
}

.orderTrash.ui-state-hover2 {
  background-color: #888;
  z-index: 990;
}

.customer-droppable {
  padding: 0.12rem;
  margin-top: 0.8rem;
  background: #fafafa;
  filter: invert(5%);
}

.tab-pane.ui-state-hover3,
.customer-droppable.ui-state-hover3 {
  border-width: 0.15rem;
  border-color: steelblue;
  border-style: groove;
}

.ui-state-hover,
.ui-state-hover2,
.ui-state-hover3 {
  z-index: unset;
}

.timing-droppable.ui-state-hover2,
.customer-droppable.ui-state-hover2,
.menuItem-droppable.ui-state-hover2 {
  filter: invert(80%);
}

.customer.quick-drop {
  font-size: 2rem;
  height: unset;
}

.customer.quick-drop {
  background: rgb(250, 248, 234);
  color: darkslategray;
  padding: 0.5rem 0.7rem;
  box-shadow: 2px 2px 6px;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.customer.quick-drop.ui-state-hover2 {
  background: rgb(250, 248, 154);
}

div.byTiming,
div.byCustomer {
  margin: 0.15rem;
  /*height: 1.85rem;*/
  overflow: unset;
  background-color: white;
}

div.state.selected {
  visibility: hidden;
}

.byTiming.draggable.state_ordered.highlight,
.byCustomer.draggable.state_ordered.highlight {
  border-color: #222;
}

.pulse {
  position: relative;
  border: none;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  border-radius: 5%;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 1.25s 4 cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s 4 cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s 4 cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s 4 cubic-bezier(0.66, 0, 0, 1);
}

.pulse-infinite {
  position: relative;
  border: none;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-background-infinite {
  position: relative;
  border: none;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse-background 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse-background 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse-background 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse-background 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 1.2rem rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 1.2rem rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 1.2rem rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 1.2rem rgba(232, 76, 61, 0);
  }
}

@-webkit-keyframes pulse-background {
  to {
    border: darkred;
  }
}
@-moz-keyframes pulse-background {
  to {
    border: darkred;
  }
}
@-ms-keyframes pulse-background {
  to {
    border: darkred;
  }
}
@keyframes pulse-background {
  to {
    filter: invert(20%);
  }
}

.customer-droppable.hidden3 {
  visibility: hidden;
  padding: 0;
  margin: 0 0.71rem;
  border: white 0 solid;
  height: 0;
}

.categoryContent {
  display: flex;
  color: black;
  background-color: inherit;
  flex-wrap: nowrap;
  text-shadow: 0 0 6px white;
}

.menuItem-price {
  padding-bottom: 0.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  padding-right: 0.5rem;
  min-width: 3rem;
  font-size: 1.3rem;
}

.menuItem-name {
  font-weight: ligher;
}

.menuItem-order.state-paid {
  background-color: khaki;
  color: gray;
}

.menuItem-order.orderedByCustomer {
  background-color: antiquewhite;
  color: gray;
  font-weight: normal;
}

td.orderedByName {
  background-color: transparent;
}

.tableMate.orderedByName {
  background: inherit;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  border: 0.05rem solid rgba(0, 0, 0, 0.125);
  line-height: 1.1rem;
  padding-top: 0.2rem;
  box-shadow: 1px 1px 2px grey;
  display: none;
}

td.orderedByName div.orderedByName {
  filter: invert(0);
  background-color: inherit;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0.2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  border: 0.05rem solid rgba(0, 0, 0, 0.125);
  line-height: 1.1rem;
  padding-top: 0.2rem;
  box-shadow: 1px 1px 2px grey;
}
td.orderedByName div.orderedByName.orderedByCustomer {
  background-color: antiquewhite;
}

.menuItem-order.first .tableMate.orderedByName {
  display: unset;
}

.menuItem-order.orderedByName.first {
  margin-top: 1.1rem;
}

#byCustomer.tab-pane .menuItem-add,
#byTiming.tab-pane .menuItem-add,
.badge.deactivate,
.badge.delete,
.selectable.paying-customer.selected .badge.customer-status,
.badge.choose-customer,
.badge.increment {
  display: none;
}
#byCustomer.tab-pane .menuItem-order.selectable.selected .menuItem-add,
#byTiming.tab-pane .menuItem-order.selectable.selected .menuItem-add,
.selectable.customer.main.selected .badge.deactivate,
.selectable.customer.main.selected .badge.delete,
.selectable.paying-customer.selected .badge.qrcode,
.selectable.paying-customer.selected .badge.choose-customer,
.selectable.paying-customer.selected .badge.increment {
  display: unset;
}

.menuItem-name-label {
  min-width: 11rem;
  flex-grow: 1;
  display: flex;
  flex-flow: row;
  flex-shrink: 25;
  font-size: 1.4rem;
  align-items: center;
}
.menuItem-name-label span:nth-child(2) {
  margin-left: 0.5rem;
}

.menuItem,
.menuItemVariation {
  background-color: #ffbb00;
  border-radius: 0.5rem;
  border: unset;
  font-size: 1.4rem;
  /*max-width: 9rem;*/
  padding: 0;
  font-weight: 400;
  vertical-align: middle;
  line-height: 1.8rem;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex-basis: 14%;
  word-break: break-word;
  margin: 2.5px;
  box-shadow: inset -4px -4px 6px rgba(0, 0, 0, 0.1), inset 4px 4px 3px rgba(255, 255, 255, 0.3) !important;
}
.menuItem >div,
.menuItemVariation >div {
  flex-grow:1;
  display:flex;
  flex-flow:column
}

.menuItem:not(.selected2),
.menuItemCategory:not(.selected2),
.menuItemVariation:not(.selected2) {
  box-shadow: 0px 0px 6px lightgray;
}

.menuItem:hover .defaultQuantity:hover,
.menuItemVariation:hover .defaultQuantity:hover {
  background-color: darkblue !important;
  color: white !important;
  text-shadow: unset;
}

.menuItem:hover .defaultQuantity,
.menuItemVariation:hover .defaultQuantity,
.menuItem:hover,
.menuItemVariation:hover {
  background-color: blue !important;
  background-image: unset !important;
  color: white !important;
  text-shadow: unset;
}

.menuItemVariation {
  background-color: orange;
}

.menuItemVariation {
  width: 100%;
}

li.menuItem text.description {
  opacity: 0.8;
}

.menuItemCategoryBadge {
  position: absolute;
  right: -0.57rem;
  bottom: -0.2rem;
}

.menuItemCategory.hidden {
  display: none;
}

.menuItemCategory:not(.selected2) .menuItem-image {
  display:flex;
  position:relative;
  flex-flow: column;
  justify-content:center;
  flex-grow:1;
  filter:inverse(1)
}

.menuItemCategory:not(.selected2) .menuItem-image {
  position: relative;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background-size: 9rem 9rem;
  background-repeat: no-repeat;
  background-position: 50% 0;
  padding-top: 9rem;
}

.menuItemCategory.selected2 .menuItem-image {
  background-image: unset !important;
  padding-top: 0.5rem;
}

.menuItem .menuItem-image .menuItem-name2 {
  display:none;
}

.menuItem .menuItem-image {
  position: relative;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background-size: 9rem 9rem;
  background-repeat: no-repeat;
  background-position: 50% 0;
  padding-top: 9rem;
}

.orderCategoryImage {
  position: absolute;
  left: 0;
  width: 2rem;
  top: 50%;
  transform: translateY(-50%);
}

.highlight-3 {
  -webkit-animation: highlight3 1.25s 1cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: highlight3 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: highlight3 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
  animation: highlight3 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
}

.highlight-4 {
  -webkit-animation: highlight3 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: highlight3 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: highlight3 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: highlight3 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.highlight-5 {
  -webkit-animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.highlight-5 input[type='text'] {
  -webkit-animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: highlight4 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.highlight-6 {
  -webkit-animation: highlight5 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: highlight5 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: highlight5 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: highlight5 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}


@-webkit-keyframes highlight3 {
  to {
    background-color: red;
    color: white;
    filter: invert(100%);
  }
}
@-moz-keyframes highlight3 {
  to {
    background-color: red;
    color: white;
    filter: invert(100%);
  }
}
@-ms-keyframes highlight3 {
  to {
    background-color: red;
    color: white;
    filter: invert(100%);
  }
}
@keyframes highlight3 {
  to {
    background-color: red;
    color: white;
    filter: invert(100%);
  }
}

@-webkit-keyframes highlight5 {
  to {
    color: white;
    filter: invert(100%);
  }
}
@-moz-keyframes highlight5 {
  to {
    color: white;
    filter: invert(100%);
  }
}
@-ms-keyframes highlight5 {
  to {
    color: white;
    filter: invert(100%);
  }
}
@keyframes highlight5 {
  to {
    color: white;
    filter: invert(100%);
  }
}

@keyframes highlight4 {
  to {
    background-color: yellow;
  }
}

.highlight-error {
  -webkit-animation: highlight-error 1.25s 1cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: highlight-error 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: highlight-error 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
  animation: highlight-error 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes highlight-error {
  to {
    background: red;
    color: white;
  }
}
@-moz-keyframes highlight-error {
  to {
    background: red;
    color: white;
  }
}
@-ms-keyframes highlight-error {
  to {
    background: red;
    color: white;
  }
}
@keyframes highlight-error {
  to {
    background: red;
    color: white;
  }
}

.user_comment {
  font-style: italic;
  background-color: green;
  color: white;
  padding: 0.14rem;
  border-radius: 0.357rem;
}

.restaurant_comment {
  font-style: italic;
  background-color: red;
  color: yellow;
  padding: 0.14rem;
  border-radius: 0.357rem;
}

div#zoomlevel {
  color: yellow;

  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10000;
  font-size: 2rem;
  font-weight: bold;
}

.no-close .ui-dialog-titlebar-close {
  display: none;
}
.eatwithme.ui-dialog {
  max-width: 100%;
}

.no-close .ui-dialog-titlebar-close {
  display: none;
}

.eatwithme .ui-dialog-title {
  font-size: 2rem;
  text-align: center;
}

.eatwithme .ui-dialog-content {
  font-size: 2rem;
  word-break: break-word;
  text-align: center;
}

.eatwithme .ui-dialog-buttonset button {
  font-size: 2rem;
}

/*
.sk-circle {
  background-color: cornflowerblue;
  border-radius: 100%;
}*/

.jquery-loading-modal__bg {
  background-color: transparent;
}

.sk-circle .sk-child:before {
  background-color: coral !important;
}

.sk-circle.icon-arrows-ccw:before {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  font-size: 20px;
  color: coral;
}

.badge.push {
  box-shadow: 0.1rem 0.1rem 0.5rem grey;
}

.fadedabit {
  opacity: 0.8;
}

img.user-img {
  border-radius: 25%;
  height: 28px;
}

.menuItem-name img {
  height: 1.4rem;
}

.bar .icon {
  position: static;
}

.bar-nav.bar .icon {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.bar-nav.bar .btn {
  padding: 10px;
  box-shadow: 2px 2px 8px grey;
}

.btn-info.focus,
.btn-info:hover {
  box-shadow: unset;
  color: inherit;
  background-color: inherit;
}

.btn-info[default=true] {
  box-shadow: unset;
  color: inherit;
  background-color: lightgray;
}


.bar-header .icon {
  position: static;
  font-size: 1.71rem;
}

.bar-footer .icon {
  position: static;
  font-size: 2rem;
}

.bar .title {
  padding-left: 0;
  margin-right: 0;
  position: unset;
  width: unset;
  align-self: center;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  font-size: 27px;
}
.bar.bar-nav {
  display: flex;
  height: unset;
  padding: 4 pxrem;
  align-items: center;
  font-size: 12px;
}

#editTable .modal-header #zoom {
  display: flex;
}

.tokens-container.form-control {
  font-size: unset;
}

.menuItemCategory.selected,
.menuItem.selected {
  outline: unset !important;
  border-color: unset !important;
}

.menuItemCategory {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 0.42rem;
  justify-content: flex-start;
  position: relative;
  font-size: 1.5rem;
  flex-grow: 1;
  background-color: #e6d32a;
  flex-basis: 14%;
  margin: 3px;
  min-width: 7rem;
  box-shadow: inset -7px -7px 6px rgba(0, 0, 0, 0.1), inset 7px 7px 3px rgba(255, 255, 255, 0.3) !important;
}
.menuItemCategory:hover {
  background-color: blue !important;
  background-image: unset !important;
  color: white !important;
}

.menuItemCategoryLabel {
  font-size: 1.5rem;
  color: unset;
  background-color: unset;
  white-space: wrap;
  word-break: break-word;
  text-align: center;
  left: 50%;
  top: 0;
  position: relative;
  transform: translate(-50%, -0%);
}

.variationHead.selected2 {
  width: 100%;
}
.menuItemCategory.selected2 {
  width: -webkit-fill-available;
  max-width: 100%;
  margin: -3.57rem 0 0;
  position: absolute;
  height: 3.57rem;
  padding-top: 0;
  padding-bottom: 0.357rem;
}

.menuItemCategory.selected2 .menuItemCategoryLabel {
  width: unset;
  height: 1.85rem;
  font-size: unset;
  top: unset;
  padding-bottom: 0.14rem;
}

.menuItemCategory.selected2 .menuItem-image {
  background-image: unset;
  width: 100%;
  flex-flow: column;
  display: flex;
}

#orderListByCustomer ul.customer-list,
#orderListByCustomer,
#orderListByTiming {
  flex-flow: row;
  flex-wrap: wrap;
}

#orderListByCustomer ul.customer-list > li {
  margin-left: 1.8rem;
  display: flex;
  flex-flow: column;
}

#orderListByCustomer ul.customer-list > li {
  flex-grow: 1;
  flex-basis: 18%;
  min-width: 25rem;
  margin: 1rem;
}

#orderListByCustomer > li,
#orderListByTiming > li {
  flex-grow: 1;
  flex-basis: 18%;
  min-width: 28rem;
  margin: 1rem;
}

.overview .bar img.user-img {
  border: 2px solid gray;
  border-style: dotted;
}

#editTable .modal-footer [class^="icon-"]:before {
  margin-left: 0;
  margin-right: 0;
}

[class*=" icon-"]:before {
  margin-left: 0;
  margin-right: 0;
}

#editTable .modal-footer .i_takeaway:before,
#editTable .modal-footer [class^="icon-"]:before,
#editTable .modal-footer [class*=" icon-"]:before {
  font-size: 20px;
  margin: 0;
  margin-right: 3px;
}

#editTable .modal-footer .n[class^="icon-"]:before,
#editTable .modal-footer .n[class*=" icon-"]:before {
  left: 0;
}

#editTable .modal-footer button,
#editTable .modal-footer button span.nn {
  white-space: normal;
  line-height: 1;
}

#editTable .modal-footer button > div {
  display: flex;
}

.btn.btn-primary.disabled {
  background-color: transparent;
  color: #007bff83;
}

@media (min-width: 0rem) and (max-width: 487px) {
  body {
    font-size: 1rem !important;
  }
  .bar .btn,
  .bar .icon {
    font-size: 37px;
    background-color: transparent !important;
  }
  .bar .btn.btn.btn-primary.active {
    color: red;
  }

  .bar #reprint-receipt.icon {
    font-size: 21px;
    width: 30px;
    background-color: transparent !important;
    border-radius: unset;
  }
  #editTable .icon {
    font-size: 18px;
    margin-left: 10px;
  }
  #editTable .bar [class^="icon-"]:before {
    font-size: 40px;
  }
  #editTable .icon-network-connected {
    background-size: 20px 20px;
  }
  .overview .bar img.user-img {
    height: 50px;
    width: 50px;
    margin-left: -7px;
    margin-top: -7px;
    border-width: 1.4px;
  }
  #editTable .modal-footer .i_takeaway:before,
  #editTable .modal-footer [class*=" icon-"]:before {
    font-size: 17px;
  }
  #editTable .modal-footer .n[class^="icon-"]:before,
  #editTable .modal-footer .n[class*=" icon-"]:before {
    left: 50%;
    font-size: 17px;
  }
  .bar button {
    font-size: 30px;
  }
  #main .bar,
  #main .bar button {
    font-size: 10px;
    margin-right: 4px;
  }
  #main .bar .mytitle {
    padding-right: 7.1px;
    margin-left: 7.1px;
    line-height: 11.4px;
    font-size: 5.7px;
    align-self: center;
    width: 30.7px;
  }
  #editTable .modal-footer button span.nn {
    display: none;
  }
  #editTable .modal-footer button:disabled {
    display: none;
  }
  #editTable .modal-footer button {
    justify-content: center !important;
    font-size: 0;
    height: 50px;
    background-size: 50px 50px;
    background-position: 50%;
    border-radius: 8px;
    margin: 0px;
    box-shadow: none;
    border: unset;
    padding: 12px !important;
  }
  .menuItemCategory.selected2 .menuItemCategoryLabel {
    height: 1.42rem;
    padding-top: 0.21rem;
  }
  .menuItemCategory.selected2 {
    height: 3.4rem;
    margin-top: -3.6rem;
    padding-bottom: 0.14rem;
  }
  table.byCustomer .timing.customer-droppable.list-group-item {
    flex-flow: column;
  }
  .badge {
    font-size: 1.2rem;
  }
  .badge.delete,
  .badge.deactivate {
    display: none;
  }
  .menuItem-name {
    word-break: break-word;
  }
  .introjs-tooltip {
    font-size: 1rem;
  }
  .introjs-button {
    font-size: 0.78rem;
  }
  .icon-help,
  .icon.icon-nav-back,
  .icon.icon-addlogin,
  .icon.icon-logout,
  img.user-img {
    background-size: 40px 40px;
  }

  #main.login,
  h1,
  .icon-login {
    font-size: 3rem;
  }
  .modal#orderMenuItem .modal-header h5 {
    font-size: 1.6rem;
  }

  .menuItem .menuItem-image span.menuItem-name {
    background-position: 50% 1rem;
    padding-top: 7.4rem;
    -webkit-text-stroke: 4px;
    color:rgba(255, 255, 255, 0.303);
    white-space: nowrap;
  }
  .menuItem .menuItem-image span.menuItem-name2 {
    display:block;
    position: absolute;
    left: 50%;
    bottom: 0%;
    padding-bottom: .1rem;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  .menuItem .menuItem-image {
    background-position: 50% 1rem;
    padding-top: 7.4rem;
  }

  .menuItem:hover .menuItem-image,
  .menuItemVariation:hover .menuItem-image {
    color: black;
  }

  #right_pane .defaultQuantity {
    height: 3.8rem;
  }
}

.btn {
  background-color: #a0a0a030;
  color: black;
}
.btn.btn-primary {
  color: #007bff;
}
.btn.btn-primary:focus,
.btn.btn-primary:hover {
  background-color: #007bff80;
  color: white;
}

.btn.btn-primary:disabled {
  background-color: transparent;
  color: #007bff83;
}
.btn.btn-danger:disabled {
  background-color: transparent;
  color: #dc3546a1;
}
.btn.btn-secondary:disabled {
  background-color: transparent;
  color: #5a6268a1;
}
.btn.btn-success:disabled {
  background-color: transparent;
  color: #28a74698;
}
.btn.btn-secondary:hover:not(:disabled),
.btn.btn-danger:hover:not(:disabled),
.btn.btn-success:hover:not(:disabled),
.btn.btn-primary:hover:not(:disabled) {
  /*color: inherit;*/
}
.btn.btn-secondary {
  color: #5a6268;
}
.btn.btn-success {
  color: #28a745;
}
.btn.btn-danger {
  color: #dc3545;
}
.btn.btn-positive {
  color: green;
  background-color: #00800018;
  border-color: green;
}
.btn.btn-positive:hover {
  background-color: green !important;
  color: white !important;
}
.btn.btn-negative {
  color: red;
  background-color: #ff000038;
  border-color: red;
}
.btn.btn-negative:hover {
  background-color: red;
  color: white;
}
.btn.btn-secondary:hover {
  color: white;
}

.btn-group-toggle .btn.focus,
.btn-group-toggle .btn:focus,
.btn-group-toggle .btn:hover {
  /*background-color: inherit;
  color: inherit;*/
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: white;
}

#editTable .modal-footer button {
  font-size: 1.3vw;
  height: 53px;
  margin: 3px;
  border-radius: 8px;
  flex: 1;
  white-space: normal;
  line-height: 1;
  padding: 17px;
}


@media screen and (max-width: 1000px) {
  #editTable .modal-footer button {
    font-size: 0;
    height: 25px;
    background-position: 50%;
    background-size: 50px 50px;
    border-radius: 8px;
    margin: 2px;
  }
  .menuItemHeader .myTitle {
    display: none;
  }

  .menuItemHeader .dropdown #dropdownRestaurantButton {
    font-size: 1vw;
  }
}

@media (min-width: 488px) and (max-width: 1399px) {
  body {
    font-size: 1rem !important;
  }
  .bar .icon {
    font-size: 30px;
  }
  #editTable .icon {
    font-size: 18px;
    margin-left: 10px;
  }
  #editTable .bar [class^="icon-"]:before {
    font-size: 40px;
  }
  body .icon-network-connected {
    background-size: 27px 27px;
  }
  .overview .bar img.user-img {
    height: 50px;
    width: 50px;
    margin-left: -10.7px;
    margin-top: -7.1px;
    border-width: 1.4px;
  }
  .bar button {
    font-size: 30px;
  }
  #main .bar,
  #main .bar button {
    font-size: 20px;
  }
  #main .bar .mytitle {
    padding-right: 7.1px;
    margin-left: 7.1px;
    line-height: 11.4px;
    align-self: center;
  }
  #editTable .modal-footer button {
    font-size: 0px;
    height: 50px;
    background-position: 50%;
    background-size: 50px 50px;
    border-radius: 8px;
    margin: 2px;
    justify-content: center;
    display: flex;
    flex-flow: column;
  }
  #editTable .modal-footer button text {
    font-size: min(16px, calc(163vw / 100));
  }
  .menuItemCategory.selected2 .menuItemCategoryLabel {
    height: 2.14rem;
    padding-top: 0.21rem;
  }
  .menuItemCategory.selected2 {
    height: 3.57rem;
    margin: -3.57rem;
    padding-bottom: 0.357rem;
  }
  .introjs-tooltip {
    font-size: 1.14rem;
  }
  .introjs-button {
    font-size: 0.92rem;
  }
  .icon-help,
  .icon.icon-nav-back,
  .icon.icon-addlogin,
  .icon.icon-logout,
  img.user-img {
    background-size: 5rem 5rem;
  }

  #main.login,
  h1,
  .icon-login {
    font-size: 3rem;
  }
  .modal#orderMenuItem .modal-header h5 {
    font-size: 1.6rem;
  }
}

@media (min-width: 1400px) {
  body {
    font-size: 1rem;
  }
  #editTable .modal-header .icon,
  .bar .icon {
    font-size: 18px;
    color: crimson;
    margin-left: 7px;
  }

  .bar .icon.icon-addlogin {
    width: 3rem;
    height: 3rem;
    margin-top: 0.4rem;
  }
  .overview .bar a.icon.pull-left {
    height: 41px;
  }
  .overview .bar img.user-img {
    height: 58px;
    margin-left: -23px;
    margin-top: -10px;
  }
  #main .bar,
  #main .bar button:not(.dropdown-item) {
    font-size: 16px;
  }

  #main .bar .title {
    word-brake: brake-word;
    padding-right: 0.3rem;
    margin-left: 0.3rem;
    line-height: 1rem;
  }
  .introjs-tooltip {
    font-size: 1.85rem;
  }
  .introjs-button {
    font-size: 1.42rem;
  }
  .modal#orderMenuItem .modal-header h5 {
    font-size: 1.6rem;
  }
  #editTable .modal-footer button {
    font-size: 20px;
    height: 53px;
    margin: 3px;
    border-radius: 8px;
    flex: 1;
    white-space: normal;
    line-height: 1;
  }
}

@media (min-height: 0px) {
}

@media (min-height: 400px) {
}

@media (min-height: 800px) {
}

.form-control {
  border-width: 0.07rem;
}

.flag-icon {
  width: 18.333333em;
  line-height: 2.8em;
}

.round-button-3d {
  padding-right: 0;
  padding-left: 0;
  height: 32px;
  width: 32px;
  border: none;
  z-index: 4;
  color: darkslateblue;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
}

.round-button-3d.positive {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#afff00ff), to(#3fff00ff));
  background-image: -webkit-linear-gradient(top, #afff00ff, #3fff00ff);
  background-image: -moz-linear-gradient(top, #afff00ff, #3fff00ff);
  background-image: -ms-linear-gradient(top, #afff00ff, #3fff00ff);
  background-image: -o-linear-gradient(top, #afff00ff, #3fff00ff);
}

.round-button-3d.negative {
  color: red;
}

.round-button-3d i.negative {
  color: red;
}

.custom-control-input:not(:disabled) ~ .custom-control-label::before {
  border: 1px solid gray;
}

.rcrop-wrapper .rcrop-croparea {
  border: 1px dashed;
}

#updateLabel {
  position: fixed;
  bottom: 0;
  right: 5px;
  font-size: 12px;
  z-index: 1;
}
i.fa.fa-calendar {
  content: "\e80f";
  font-family: fontello;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
#daterange {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  width: 18rem;
}
/*
.select2-container .select2-selection--single {
  height: 2rem;
}*/

table th input[type="text"] {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  height: 2rem;
}

.floatThead-container {
  flex-shrink: 0;
}

#speechRecording {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: red;
  border: 1px solid gray;
  z-index: 10000;
}

div#download-progress {
  color: darkgray;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 20000;
  font-size: 20px;
  font-weight: bold;
  background-color: #212529a3;
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div#download-progress svg {
  animation: name duration timing-function delay iteration-count direction fill-mode;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.4em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: 0.2em;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.6em) translateY(-50%);
  -ms-transform: translateX(1.6em) translateY(-50%);
  transform: translateX(1.6em) translateY(-50%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
