#waiterScreen #main {
    display: flex;
    flex-flow: column;
}

#waiterScreen #main > :not(#simpleOrderMain) {
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

#waiterScreen #main > :not(#simpleOrderMain) > * {
    font-size: 10vmin;
    margin-top: 1vmin;
    margin-left: 1vmin;
    margin-right: 1vmin;
    padding-left: 1vmin;
    padding-right: 1vmin;
    border: 2px solid crimson;
    display: flex;
    flex-flow: column;
    line-height: 10vmin;
}

#waiterScreen #main > :not(#simpleOrderMain) > * .duration {
    font-size: 2vmin;
    align-self: flex-end;
    line-height: 2vmin;
    
}

#waiterScreen #main .header:not(#simpleOrderMain) {
    font-size: 5vmin;
    width: 100%;
    background: crimson;
    flex-grow: 0;
    color: white;
    justify-content: center;
}

#waiterScreen #main #simpleOrderMain .modal-body,
#waiterScreen #main #simpleOrderMain {
    font-size: 20px;
}
