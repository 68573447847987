
#printers #accessToken:placeholder-shown {
    display:none
}

#printers #accessToken:placeholder-shown ~ div {
    display:none;
}

#printers #accessToken:not(:placeholder-shown) {
    width: 16rem;
}

#printers #accessToken:not(:placeholder-shown) ~ div.btn {
    color: white;
}

#printers #accessToken:not(:placeholder-shown) + input {
    display:none;
}