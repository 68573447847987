@media print {

  .visible-print  { display: inherit !important; }
  .hidden-print   { display: none !important; }

  html {
    overflow: visible !important;
    height: unset !important;
    width: 100vw !important;
  }
  body {
    height: unset !important;
    position: unset;
  }

  .hide-print {
    display: none;
  }

  tr {
    background-color: unset !important;
  }

  .stock-actions input,
  .select2-container--default .select2-selection--single {
    border: unset !important;
  }

  select,
  textarea,
  textarea.form-control,
  ul.form-control,
  .custom-select,
  input[type="text"],
  input[type="search"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="tel"],
  input[type="color"] {
    border: unset;
    background-color: unset !important;
  }

  .stock-actions table tbody#entriesTable tr td input:disabled {
    background-color: unset !important;
  }

  .select2.select2-container .selection {
    color: black;
    display: block;
  }

  body,
  html {
    background: unset !important;
    background-color: transparent !important;
  }

  table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-active,
  table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-active:hover,
  table.fancytree-ext-table tbody tr:hover,
  table.fancytree-ext-table tbody tr.fancytree-active:hover,
  table.fancytree-ext-table tbody tr.fancytree-selected:hover,
  table.fancytree-ext-table tbody tr.fancytree-active {
    outline: unset;
  }

  .mobile-only,
  .modal-backdrop,
  .jquery-loading-modal,
  button,
  .buttonbar,
  .dropdown-wrapper,
  .modal-footer {
    display: none !important;
  }


  .modal-open {
    overflow: visible !important;
    height: unset !important;
  }

  #main,
  body > #root,
  .stock-actions,
  .modal-dialog,
  .modal-content,
  .modal {
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    position: unset !important;
    border: unset;
    border-radius: unset;
  }

  .stock-actions * {
    overflow: visible !important;
  }

  .stock-actions #listFreezeTable,
  .stock-actions #entriesFreezeTable {
    height: auto;
    overflow: visible !important;
    margin: 0;
    padding: 0;
    width: 100% !important;
  }

  .select2-selection__clear,
  .react-bootstrap-daterangepicker-container,
  .select2-selection__arrow {
    display: none !important;
  }

  .stock-actions #entriesFreezeTable table tbody tr td.entries div:not(.total),
  .stock-actions #listFreezeTable table tbody tr td.entries div:not(.total) {
    display: block;
  }
  .stock-actions #entriesFreezeTable table tbody tr td.entries div.total,
  .stock-actions #listFreezeTable table tbody tr td.entries div.total {
    display: none;
  }

  .stock-actions div#entriesFreezeTable thead,
  .stock-actions div#entriesFreezeTable tfoot {
    position: relative;
  }

  .stock-actions #entriesFreezeTable table tr,
  .stock-actions #listFreezeTable table tr {
    border: 1px solid black;
  }

  .stock-actions #entriesFreezeTable table td,
  .stock-actions #entriesFreezeTable table th,
  .stock-actions #listFreezeTable table td,
  .stock-actions #listFreezeTable table th {
    min-width: unset;
    background-color: transparent !important;
    position: relative;
  }

  .stock-actions #entriesFreezeTable table th,
  .stock-actions #listFreezeTable table th {
    white-space: break-word;
  }

  .stock-actions * {
    background-color: transparent !important;
  }

  .stock-actions th {
    max-width: unset !important;
    min-width: unset !important;
    white-space: pre-wrap !important;
    word-break: break-word;
  }
  .stock-actions .entries {
    flex-basis: unset !important;
    max-width: unset !important;
    min-width: unset !important;
  }

  .stock-actions #entriesFreezeTable table th,
  .stock-actions #listFreezeTable table th {
    vertical-align: middle !important;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    white-space: break-spaces;
  }

  .stock-actions .select2-container .select2-selection--single,
  .stock-actions div#entriesFreezeTable table tbody td span.select2.select2-container {
    height: unset;
    overflow: visible;
    vertical-align: bottom;
  }

  .stock-actions .table td,
  .stock-actions tbody tr td {
    overflow: visible;
  }
  
  .stock-actions div#entriesFreezeTable table td input {
    min-width: min-content;
    width: 100% !important;
  }

  /*!
 * FullCalendar v3.0.1 Print Stylesheet
 * Docs & License: http://fullcalendar.io/
 * (c) 2016 Adam Shaw
 */

/*
 * Include this stylesheet on your page to get a more printer-friendly calendar.
 * When including this stylesheet, use the media='print' attribute of the <link> tag.
 * Make sure to include this stylesheet IN ADDITION to the regular fullcalendar.css.
 */


* {
  overflow: visible !important;
  animation: unset !important;
 }

 /* naturally position events, vertically stacking them */
.fc-time-grid .fc-event {
	position: static !important;
	margin: 3px 2px !important;
}

/* for events that continue to a future day, give the bottom border back */
.fc-time-grid .fc-event.fc-not-end {
	border-bottom-width: 1px !important;
}

/* indicate the event continues via "..." text */
.fc-time-grid .fc-event.fc-not-end:after {
	content: "...";
}

/* for events that are continuations from previous days, give the top border back */
.fc-time-grid .fc-event.fc-not-start {
	border-top-width: 1px !important;
}

/* indicate the event is a continuation via "..." text */
.fc-time-grid .fc-event.fc-not-start:before {
	content: "...";
}

/* time */

/* undo a previous declaration and let the time text span to a second line */
.fc-time-grid .fc-event .fc-time {
	white-space: normal !important;
}

/* hide the the time that is normally displayed... */
.fc-time-grid .fc-event .fc-time span {
	display: none;
}

/* ...replace it with a more verbose version (includes AM/PM) stored in an html attribute */
.fc-time-grid .fc-event .fc-time:after {
	content: attr(data-full);
}
/* undo the min-height 100% trick used to fill the container's height */
.fc-time-grid {
	min-height: 0 !important;
}

/* don't display the side axis at all ("all-day" and time cells) */
.fc-agenda-view .fc-axis {
	display: none;
}

/* don't display the horizontal lines */
.fc-slats,
.fc-time-grid hr { /* this hr is used when height is underused and needs to be filled */
	display: none !important; /* important overrides inline declaration */
}

/* let the container that holds the events be naturally positioned and create real height */
.fc-time-grid .fc-content-skeleton {
	position: static;
}

/* in case there are no events, we still want some height */
.fc-time-grid .fc-content-skeleton table {
	height: 4em;
}

/* kill the horizontal spacing made by the event container. event margins will be done below */
.fc-time-grid .fc-event-container {
	margin: 0 !important;
}


.fc th,
.fc td,
.fc hr,
.fc thead,
.fc tbody,
.fc-row {
	border-color: #ccc !important;
	background: #fff !important;
}

/* kill the overlaid, absolutely-positioned components */
/* common... */
.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton,
/* for timegrid. within cells within table skeletons... */
.fc-bgevent-container,
.fc-business-container,
.fc-highlight-container,
.fc-helper-container {
	display: none;
}

/* don't force a min-height on rows (for DayGrid) */
.fc tbody .fc-row {
	height: auto !important; /* undo height that JS set in distributeHeight */
	min-height: 0 !important; /* undo the min-height from each view's specific stylesheet */
}

.fc tbody .fc-row .fc-content-skeleton {
	position: static; /* undo .fc-rigid */
	padding-bottom: 0 !important; /* use a more border-friendly method for this... */
}

.fc tbody .fc-row .fc-content-skeleton tbody tr:last-child td { /* only works in newer browsers */
	padding-bottom: 1em; /* ...gives space within the skeleton. also ensures min height in a way */
}

.fc tbody .fc-row .fc-content-skeleton table {
	/* provides a min-height for the row, but only effective for IE, which exaggerates this value,
	   making it look more like 3em. for other browers, it will already be this tall */
	height: 1em;
}


#bookTable .fc-event * {
  background-color: white !important;
  color: black !important;
}
#bookTable .fc-event {
  background-color: white !important;
  border: 1px solid black !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid thead {
  display: table-header-group;
}

}
