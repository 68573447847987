li.mate span.times {
    display: none;
}
li.mate.active span.times {
    display: unset;
}
.paymentMethod {
    flex-grow: 1;
    text-align: left !important;
}
.input-group-prepend {
    flex-grow: 1;
}
.custom-button:focus {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

#tableMatePaid li.mate.active div#tip {
    display: flex;
    flex-flow: row;
    flex-shrink: 0;
}

#tableMatePaid li.mate:not(active) div#tip {
    display: none;
}

#tableMatePaid #numbersBlock {
    padding-left: 0.6em;
    width: 19em;
    height: unset;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: 0.4em;
    display: flex;
    flex-wrap: wrap;
    height: 17em;
}
#tableMatePaid #numbersBlock label {
    margin: 0.04em;
    min-width: 3em;
    flex-grow: 1;
    font-size: 1.6em;
    height: 2em;
    padding: 2px;
    position: relative;
}

#tableMatePaid .modal-content {
    font-size: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: unset;
    height: 100%;
}

#tableMatePaid div.amountPaid input {
    width: 20%;
    height: 2.4em;
    font-size: 1.6em;
    margin-right: 1rem;
    color: black;
    text-align: right;
    padding: 0;
}

#tableMatePaid span.amountToBePaid {
    font-size: 2em;
    vertical-align: middle;
    padding: 0.4em;
    margin-right: 1em;
    width: 3em;
    text-align: right;
}

#tableMatePaid #matesBlock {
    margin-left: unset;
    margin-right: unset;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
}

#tableMatePaid #matesBlock li:not(.active) .vat-details-block {
    display: none !important
}

#tableMatePaid .modal-content {
    position: relative;
    transform: translateX(-50%);
}


@media(orientation: portrait) {
    #tableMatePaid .modal-body {
        flex-flow: column;
        align-items: center;
        display: flex;
    }
    #tableMatePaid #matesBlock {
        height: calc(100% - 10rem);
        border: unset;
    }
}

@media(orientation: portrait) and(min-height: 488px) and(max-height: 1024px) {}

#tableMatePaid .modal-footer {
    font-size: 19px;
}

@media(orientation: landscape) and(min-width: 0px) and(max-width: 487px) {
    #tableMatePaid .modal-body {
        font-size: 1.7rem !important;
    }
    #tableMatePaid #numbersBlock {
        font-size: 2.4rem !important;
    }
}
@media(orientation: landscape) and(min-width: 488px) and(max-width: 1024px) {
    #tableMatePaid .modal-body {
        font-size: 1rem !important;
    }
    #tableMatePaid #numbersBlock {
        font-size: 1.1rem !important;
    }
}
@media(orientation: landscape) and(min-width: 1025px) and(max-width: 1330px) {
    #tableMatePaid .modal-body {
        font-size: 1rem !important;
    }
    #tableMatePaid #numbersBlock {
        font-size: 1.4rem !important;
    }

}

#tableMatePayments .payments > div {
    border-bottom:1px solid lightgray;
    display:grid;
    grid-template-columns: 7rem 13rem 6rem 4rem 3rem 9rem 13rem 6rem;
    padding: .5rem;
}

#tableMatePayments .modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}
#tableMatePayments .payments > div > .date {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 10;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
}

#tableMatePayments .payments > div > * {
    min-height: 2.3rem;
    display: flex;
    align-items: center;
    align-self: baseline;
}

#tableMatePayments .payments > div > .customer {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 10;
    display: flex;
    align-items: center;
    height: 100%;
    align-self: center;
}

#tableMatePayments .payments > div > .type {
    grid-column-start: 3;
    grid-column-end: 4;
    text-align: center;
    justify-self: end;
    align-self: auto;
}
#tableMatePayments .payments > div > .amount {
    grid-column-start: 4;
    grid-column-end: 5;
    text-align: right;
    justify-self: end;
    align-self: auto;
}
#tableMatePayments .payments > div > .currency {
    grid-column-start: 5;
    grid-column-end: 6;
    text-align: left;
    padding-left: .1rem;
    align-self: auto;
}

#tableMatePayments .payments > div > .invoice {
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 10;
    display: flex;
    align-items: center;
    justify-self: center;
    align-self: center;
    position: relative;
    padding-right: 2rem;
}
#tableMatePayments .payments > div > .invoice span {
    padding-left: 1rem;
}

#tableMatePayments .payments > div > .printed {
    grid-column-start: 7;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 10;
    display: flex;
    align-items: center;
    justify-self: center;
    align-self: center;
    position: relative;
    padding-right: 2rem;
}

#tableMatePayments .payments > div > .printed_ok {
    grid-column-start: 7;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 10;
    display: flex;
    align-items: center;
    justify-self: center;
    justify-items: flex-end;
    align-self: center;
    position: relative;
    width: min-content;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.1rem;
}

#tableMatePayments .payments > div > .registerTip {
    grid-column-start: 8;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 10;
    display: flex;
    align-items: center;
    justify-self: center;
    align-self: center;
    position: relative;
}



#tableMatePayments .payments > div > .printed.btn-primary {
    background: #007bff;
    color: black;
}

#tableMatePayments .payments > div > .printed .icon-cancel {
    position: absolute;
    color: red;
    right: 0;
}
#tableMatePayments .payments > div > .printed .icon-check {
    position: absolute;
    color: green;
    right: 0;
}