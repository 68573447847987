div#timingSelector,
div#userSelector {
  display: flex;
  flex-shrink: 0;
  background: white;
}

div#userSelector .btn-group-toggle > button,
div#timingSelector .btn-group-toggle > button {
  margin: 2px !important;
}


div#userSelector .btn-group-toggle > button.active.sticky div span:before {
  content: "= ";
}
div#userSelector .btn-group-toggle > button.active.sticky div span:after {
  content: " =";
}

.menuItemHead .manufacturer {
  font-size: 1rem;
  color: darkslategray
}


.menuItemHead .label {
  top:0;
  right:-.8rem;
  display: flex;
  flex-flow: row;
  padding-top: .3rem;
  z-index: 1;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.menuItemHead .label span
{ 
  transform: rotate(5deg);
  font-size: .8rem;
  color: darkslategray;
  background: rgba(255, 255, 0, 0.645);
  width: min-content;
  white-space: nowrap;
  align-self: center;
  padding:.3rem;
  line-height: .3rem;
  border-radius: .7rem;
}

.list-group-item {
  /*border-radius: unset;*/
  border-radius: 0.25rem;
}

#drink_menu_tab .price,
#meal_menu_tab .price {
  font-size: 1.1rem;
  line-height: 1.1rem;
  text-align: right;
  padding-right: 0.5rem;
  word-break: normal;
  display:flex;
}

#drink_menu_tab .price.icon-cup:before,
#meal_menu_tab .price.icon-cup:before {
  line-height: 1rem;
}

div.quantity {
  text-align: right;
  padding-right: 0.5rem;
  font-size: 1.1rem;

  >span {
    white-space:nowrap;
  }

}

#right_pane div#timingSelector button#maximize {
  height: 6.4rem;
  width: 6.4rem;
  margin-right: 0.3rem;
  flex-shrink: 0;
  display: none;
}

#left_pane button#maximize {
  height: 6.4rem;
  width: 6.4rem;
  margin-right: 0.3rem;
  flex-shrink: 0;
  display: none;
  align-self: center;
}

#left_pane button#maximize.hidden {
  display: none;
}

#right_pane {
  background: white;
}

#right_pane > div > div > ul {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

#right_pane .tab-content {
  overflow: hidden;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

#right_pane .tab-content.single {
  overflow-y: auto !important;
  height: unset !important;
}

#right_pane .tab-content div.tab-pane {
  background-color: inherit;
  max-width: unset;
  padding-left: unset;
  padding-right: unset;
  flex-grow: 1;
  font-family: sans-serif;
  height: 100%;
}

#right_pane .tab-content.multiple div.tab-pane {
  height: unset;
}

#right_pane .tab-content.multiple li,
#right_pane .tab-content.single li {
  flex-basis: 100% !important;
  max-width: unset !important;
}

#right_pane .tab-content.single div.tab-pane {
  height: unset;
}

#right_pane .tab-content .tab-pane div.menu.container {
  height: 100%;
}

#right_pane .tab-content .tab-pane.categorySelected div.menu.container {
  margin-top: 0rem;
  height: calc(100% - 3.6rem);
}

#right_pane .tab-content:not(single) div.menu.container1 {
  margin-top: 3.6rem;
  height: calc(100% - 3.6rem);
}

#right_pane .tab-content.multiple {
  overflow-y: auto;
}

.tab-content.multiple .tab-pane.scrollable-y {
  overflow: hidden;
}

#right_pane #quickSearch {
  flex-shrink: 0;
  display: flex;
}

#right_pane .menuItemHead {
  flex-grow: 1;
  min-height: 2rem;
  display: flex;
  flex-flow: column;
  z-index: 1;
  position: relative;
  padding-bottom: .25rem;
  min-height: 20px;
}

@media (min-width: 0rem) and (max-width: 487px) {
  #right_pane div#timingSelector button#maximize {
    height: 25px;
    width: 25px;
    margin-right: 0;
    display: unset;
  }

  div#timingSelector .btn-group-toggle > button {
    box-shadow: none;
  }

  #left_pane button#maximize {
    height: 25px;
    width: 25px;
    display: unset;
    margin-right: 0;
  }

  div#timingSelector {
    height: 28px;
  }

  #timingSelector button {
    font-size: 15px;
    height: 25px;
  }

  div.quantity {
    font-size: 2rem;
  }

  #right_pane .tab-content .tab-pane.categorySelected div.menu.container {
    margin-top: 0;
    height: calc(100%);
  }

  div.tab-pane.menu.categorySelected .menu.container {
    margin-top: 0rem;
  }

  #right_pane .menuItemCategory:not(.selected2) .menuItemCategoryLabel.withImage {
    /*display: none*/
  }

  #right_pane .menuItemCategory.selected2 {
    display: none;
  }

  div.tab-pane.menu.categorySelected .menu.container {
    padding-top: 0px !important;
  }

  #right_pane .menuItemCategory.selected2 .menuItemCategoryLabel.withImage {
    font-size: 3rem;
    height: 5rem;
  }

  #right_pane img.menuItem-image {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  #right_pane .list-group-item.defaultQuantity {
    line-height: 4rem;
    height: 8rem;
    border-top: 1px dashed gray;
  }

  #right_pane #quickSearch {
    /*display: none;*/
  }

  #right_pane .menuItemCategoryLabel {
    font-size: 3rem;
    line-height: 3rem;
  }

  #right_pane .menuItem {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  #right_pane .menuItem,
  #right_pane .menuItemCategory {
    min-width: 15rem;
    min-height: 6rem;
    border: 1px solid lightgray;
    box-shadow: none;
  }

  #drink_menu_tab .price,
  #meal_menu_tab .price {
    font-size: 2rem;
  }

  #right_pane .menuItemHead {
    min-height: 4rem;
  }
}

#right_pane .menuItem {
  min-width: unset !important;
}

@media (min-width: 488px) and (max-width: 1099px) {
  #right_pane div#timingSelector button#maximize {
    height: 3rem;
    width: 3rem;
    display: unset;
  }

  #left_pane button#maximize {
    height: 3rem;
    width: 3rem;
    display: unset;
  }

  div#timingSelector {
    height: 3rem;
  }

  #timingSelector button {
    font-size: 2rem;
  }

  div.quantity {
    font-size: 1.4rem;
  }

  #right_pane .tab-content .tab-pane.categorySelected div.menu.container {
    margin-top: 0;
    padding-top: 0rem;
    height: calc(100%);
  }

  div.tab-pane.menu.categorySelected .menu.container {
    margin-top: 0rem;
  }

  #right_pane .menuItemCategory:not(.selected2) .menuItemCategoryLabel.withImage {
    /*display: none*/
  }

  #right_pane .menuItemCategory.selected2 {
    display: none;
  }

  #right_pane .menuItemCategory.selected2 .menuItemCategoryLabel.withImage {
    font-size: 3rem;
    height: 5rem;
  }

  #right_pane img.menuItem-image {
    top: 0%;
    transform: translate(-50%, 0%);
  }

  /*
  #right_pane .list-group-item.defaultQuantity {
    line-height: 2rem;
    height: 3rem;
    border-top: 1px dashed gray;
  }*/

  #right_pane #quickSearch {
    /*display: none;*/
  }

  #right_pane .menuItemCategoryLabel {
    font-size: 3rem;
    line-height: 3rem;
  }

  #right_pane .menuItem {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }

  #right_pane .menuItem,
  #right_pane .menuItemCategory {
    min-width: 10rem;
    min-height: 4rem;
    border: 1px solid white;
  }

  #drink_menu_tab .price,
  #meal_menu_tab .price {
    font-size: 1rem;
  }

  #right_pane .menuItemHead {
    min-height: 3rem;
  }
}

div.tab-pane.menu.categorySelected .menu.container {
  padding-top: 3.57rem;
}

#right_pane .quantity {
  margin-left: 0.3rem;
}

#right_pane .defaultQuantity {
  background-color: unset;
  border: none;
  /*border-top: 1px dashed gray;*/
  word-break: break-word;
  padding: 0.2rem;
  display: flex;
  color: darkslategray;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  height: 2.8rem;
  min-height: 24px;
}

#right_pane .defaultQuantity:hover {
  background-color: lime;
  color: darkblue;
}

#editTable #userSelector button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  font-size: 1.5rem;
  padding: 0px;
  margin: 0px;
  position: relative;
  left: 0px;
  transform: translateX(-50%));
  align-items: center;
}
