/*
html {
  height: 100%;
  overflow: hidden;
}

body {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
}*/

body > #root {
  flex-grow: 1;
  flex-shrink:1;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 100%;
}

body > #root > .App {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  flex-shrink: 1;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

body > #test_mode.top {
  width: 100%;
  text-align: center;
  background: orange;
  font-size: 12px;
  height: 18px;
  -webkit-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse-background 5.25s infinite cubic-bezier(0.66, 10, 0, 1);
}

body > #test_mode.bottom {
  top: 100%;
  width: 100%;
  text-align: center;
  background: orange;
  height: 18px;
  font-size: 12px;
  -webkit-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse-background 5.25s infinite cubic-bezier(0.66, 10, 0, 1);
}
