@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?77413546');
  src: url('../font/fontello.eot?77413546#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?77413546') format('woff2'),
       url('../font/fontello.woff?77413546') format('woff'),
       url('../font/fontello.ttf?77413546') format('truetype'),
       url('../font/fontello.svg?77413546#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?77413546#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-users:before { content: '\e800'; } /* '' */
.icon-dollar:before { content: '\e801'; } /* '' */
.icon-user:before { content: '\e802'; } /* '' */
.icon-logout:before { content: '\e803'; } /* '' */
.icon-list-add:before { content: '\e804'; } /* '' */
.icon-cog:before { content: '\e805'; } /* '' */
.icon-camera:before { content: '\e806'; } /* '' */
.icon-picture:before { content: '\e807'; } /* '' */
.icon-trash-empty:before { content: '\e808'; } /* '' */
.icon-floppy:before { content: '\e809'; } /* '' */
.icon-qrcode:before { content: '\e80a'; } /* '' */
.icon-cancel-circled2:before { content: '\e80b'; } /* '' */
.icon-list:before { content: '\e80c'; } /* '' */
.icon-right-hand:before { content: '\e80d'; } /* '' */
.icon-left-hand:before { content: '\e80e'; } /* '' */
.icon-calendar:before { content: '\e80f'; } /* '' */
.icon-loop:before { content: '\e810'; } /* '' */
.icon-home-outline:before { content: '\e811'; } /* '' */
.icon-credit-card:before { content: '\e812'; } /* '' */
.icon-thumbs-up:before { content: '\e813'; } /* '' */
.icon-hourglass:before { content: '\e814'; } /* '' */
.icon-ok-circled:before { content: '\e815'; } /* '' */
.icon-login:before { content: '\e816'; } /* '' */
.icon-plus-circled:before { content: '\e817'; } /* '' */
.icon-cancel-circled2-1:before { content: '\e818'; } /* '' */
.icon-upload:before { content: '\e819'; } /* '' */
.icon-cancel-circled:before { content: '\e81a'; } /* '' */
.icon-location:before { content: '\e81b'; } /* '' */
.icon-minus-circled:before { content: '\e81c'; } /* '' */
.icon-volume-up:before { content: '\e81d'; } /* '' */
.icon-download:before { content: '\e81e'; } /* '' */
.icon-thumbs-down:before { content: '\e81f'; } /* '' */
.icon-map:before { content: '\e820'; } /* '' */
.icon-star-filled:before { content: '\e821'; } /* '' */
.icon-print:before { content: '\e822'; } /* '' */
.icon-plus:before { content: '\e823'; } /* '' */
.icon-edit:before { content: '\e824'; } /* '' */
.icon-volume-off:before { content: '\e825'; } /* '' */
.icon-chart-bar:before { content: '\e826'; } /* '' */
.icon-clock:before { content: '\e827'; } /* '' */
.icon-thumbs-up-1:before { content: '\e828'; } /* '' */
.icon-thumbs-down-1:before { content: '\e829'; } /* '' */
.icon-record:before { content: '\e82a'; } /* '' */
.icon-paper-plane:before { content: '\e82b'; } /* '' */
.icon-attention:before { content: '\e82c'; } /* '' */
.icon-chart-line:before { content: '\e82d'; } /* '' */
.icon-minus:before { content: '\e82e'; } /* '' */
.icon-comment:before { content: '\e82f'; } /* '' */
.icon-barcode:before { content: '\e830'; } /* '' */
.icon-spin2:before { content: '\e831'; } /* '' */
.icon-restaurant:before { content: '\e832'; } /* '' */
.icon-right-open-big:before { content: '\e833'; } /* '' */
.icon-left-open-big:before { content: '\e834'; } /* '' */
.icon-down-open-big:before { content: '\e835'; } /* '' */
.icon-up-open-mini:before { content: '\e836'; } /* '' */
.icon-arrows-ccw:before { content: '\e837'; } /* '' */
.icon-th-list:before { content: '\e838'; } /* '' */
.icon-mobile:before { content: '\e839'; } /* '' */
.icon-cancel:before { content: '\e83a'; } /* '' */
.icon-direction:before { content: '\e83b'; } /* '' */
.icon-lock:before { content: '\e83c'; } /* '' */
.icon-lock-open:before { content: '\e83d'; } /* '' */
.icon-monitor:before { content: '\e83e'; } /* '' */
.icon-up-open-1:before { content: '\e83f'; } /* '' */
.icon-desktop:before { content: '\e840'; } /* '' */
.icon-down-open-1:before { content: '\e841'; } /* '' */
.icon-sort-numeric:before { content: '\e842'; } /* '' */
.icon-up-open-big:before { content: '\e843'; } /* '' */
.icon-zoom-in:before { content: '\e844'; } /* '' */
.icon-zoom-out:before { content: '\e845'; } /* '' */
.icon-zoom-in-outline:before { content: '\e846'; } /* '' */
.icon-zoom-out-outline:before { content: '\e847'; } /* '' */
.icon-plus-squared:before { content: '\e848'; } /* '' */
.icon-minus-squared:before { content: '\e849'; } /* '' */
.icon-users-outline:before { content: '\e84a'; } /* '' */
.icon-user-add-outline:before { content: '\e84b'; } /* '' */
.icon-user-delete-outline:before { content: '\e84c'; } /* '' */
.icon-user-outline:before { content: '\e84d'; } /* '' */
.icon-basket:before { content: '\e84e'; } /* '' */
.icon-ok:before { content: '\e84f'; } /* '' */
.icon-check:before { content: '\e850'; } /* '' */
.icon-menu:before { content: '\e851'; } /* '' */
.icon-truck:before { content: '\e852'; } /* '' */
.icon-cup:before { content: '\e853'; } /* '' */
.icon-forward:before { content: '\e854'; } /* '' */
.icon-info-outline:before { content: '\e855'; } /* '' */
.icon-chat:before { content: '\e856'; } /* '' */
.icon-logout-1:before { content: '\e857'; } /* '' */
.icon-gauge:before { content: '\e858'; } /* '' */
.icon-resize-full:before { content: '\e859'; } /* '' */
.icon-resize-small:before { content: '\e85a'; } /* '' */
.icon-cancel-alt:before { content: '\e85b'; } /* '' */
.icon-moon:before { content: '\e85c'; } /* '' */
.icon-moon-inv:before { content: '\e85d'; } /* '' */
.icon-mail:before { content: '\e85e'; } /* '' */
.icon-phone:before { content: '\e85f'; } /* '' */
.icon-cog-outline:before { content: '\e860'; } /* '' */
.icon-volume-high:before { content: '\e861'; } /* '' */
.icon-volume-off-1:before { content: '\e862'; } /* '' */
.icon-print-1:before { content: '\e863'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-th-thumb-empty:before { content: '\f00b'; } /* '' */
.icon-stop:before { content: '\f080'; } /* '' */
.icon-printer:before { content: '\f087'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-left:before { content: '\f0a8'; } /* '' */
.icon-right:before { content: '\f0a9'; } /* '' */
.icon-up-circled:before { content: '\f0aa'; } /* '' */
.icon-down-circled:before { content: '\f0ab'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-exchange:before { content: '\f0ec'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-keyboard:before { content: '\f11c'; } /* '' */
.icon-crop:before { content: '\f125'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-angle-circled-left:before { content: '\f137'; } /* '' */
.icon-angle-circled-right:before { content: '\f138'; } /* '' */
.icon-angle-circled-up:before { content: '\f139'; } /* '' */
.icon-angle-circled-down:before { content: '\f13a'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-level-down:before { content: '\f149'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-left2:before { content: '\f177'; } /* '' */
.icon-right2:before { content: '\f178'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-cart-plus:before { content: '\f217'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-usb:before { content: '\f287'; } /* '' */
.icon-percent:before { content: '\f295'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
