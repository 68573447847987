#TableOccupations i.fa.fa-calendar {
  font-family: "FontAwesome";
  background-image: unset;
  width: 2rem;
}

#TableOccupations .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

#TableOccupations .btn-group-toggle {
  flex-shrink: 0;
}
#TableOccupations .btn-group-toggle > * {
  flex-shrink: 0;
}

#TableOccupations #tableOccupationsFreezeTable {
  transition: flex-basis ease 1s;
}
