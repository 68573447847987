._1ALcI {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: fit-content;
}

.openingDay,
.openingDay .MuiButton-root {
    font-size: 1.4rem;
    min-height: 3.6rem;
  }
  