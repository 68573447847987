/*
#restaurantSuperAdmin .layout-pane {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column;
}

#restaurantSuperAdmin .layout-pane .nav-tabs {
  flex-shrink: 0;
}

#restaurantSuperAdmin .layout-pane .tab-content {
  height: 100%;
  overflow: hidden;
}

#restaurantSuperAdmin .layout-pane .tab-content > div {
  overflow: auto;
  height: 100%;
}
*/
#restaurantSuperAdmin input[type="checkbox"],
#restaurantSuperAdmin input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    width: 14px;
    height: 14px;
}

#restaurantSuperAdmin .tab-content .tab-pane {
    height: calc(100% - 36px);
    overflow: hidden;
    position: relative;
}

#restaurantSuperAdmin .tab-content {
    height: 100%;
}


#restaurantSuperAdmin .react-grid-cell {
    overflow: visible;

}
