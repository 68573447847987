.inputRow {
  font-weight: bold;
  font-size: 2rem;
  color: #428bca;
  padding-top: 1.2rem;
  padding-bottom: 0;
  width: 100%;
}

.inputStyle {
  border-radius: 11px;
  border: 0.5px solid lightgray;
  width: 100%;
}

.modal .modal-footer button {
  margin: 2px;
}

body {
  font-family: Mohave !important;
}
#right_pane .tab-content div.tab-pane {
  font-family: Mohave !important;
  line-height: 2.1em;
}

.menuItemHeader {
  background-color: #faf8ea;
}

body > div > #test_mode.top {
  width: 100%;
  text-align: center;
  background: orange;
  font-size: 12px;
  height: 18px;
  -webkit-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse-background 5.25s infinite cubic-bezier(0.66, 10, 0, 1);
}

body > div > #test_mode.bottom {
  top: 100%;
  width: 100%;
  text-align: center;
  background: orange;
  height: 18px;
  font-size: 12px;
  -webkit-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse-background 5.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse-background 5.25s infinite cubic-bezier(0.66, 10, 0, 1);
}

body > div#test-top,
body > div#test-bottom {
  background: orange;
  display: flex;
  flex-flow: column;
}

body > div > #backup.top,
body > div > #backup.bottom {
  background: orange;
  font-size: 12px;
  height: 24px;
  width: fit-content;
  position: relative;
  -webkit-animation: floatingevents 40s infinite linear;
  -moz-animation: floatingevents 40s infinite linear;
  -o-animation: floatingevents 40s infinite linear;
  animation: floatingevents 40s infinite linear;
}

@keyframes floatingevents {
  0% {
    transform: translateX(100vw) translateX(-100%);
  }
  50% {
    transform: translateX(0%) translateX(0vw);
  }
  100% {
    transform: translateX(100vw) translateX(-100%);
  }
}

html,
body {
  overflow: hidden;
}

/*
html {
  background: url("../img/tables-overview@2x.png");
  background-repeat: no-repeat;
  background-size: 100vmax 100vmax;
}*/

body {
  width: 100%;
  padding: 0;
  max-width: unset;
}

@media (orientation: portrait) {
  html[device="iOS"] {
    background-position: 0 20px;
    background-size: 100vmax 100vmax;
  }
}

body[device] {
  -webkit-animation: hidebackground 5s forwards;
  -moz-animation: hidebackground 5s forwards;
  -o-animation: hidebackground 5s forwards;
  animation: hidebackground 5s forwards;
}

/*
body[device] > * {
  -webkit-animation: hidebackground2 5s forwards;
  -moz-animation: hidebackground2 5s forwards;
  -o-animation: hidebackground2 5s forwards;
  animation: hidebackground2 5s forwards;
}
*/
@keyframes hidebackground {
  0% {
    opacity: 0;
    background-color: rgb(250, 248, 234, 0);
  }

  100% {
    opacity: 1;
    background-color: rgb(250, 248, 234, 1);
  }
}

@keyframes hidebackground2 {
  0% {
    opacity: 0;
    background-color: rgb(250, 248, 234, 0);
  }

  100% {
    opacity: 1;
    background-color: rgb(250, 248, 234, 1);
  }
}

.loginDialog .modal-content {
  margin: 20px;
  background-color: #191818;
  border: unset;
}

.loginDialog img.eatwithmetext {
  display: block;
}
.loginDialog .modal-content {
  max-width: 450px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  max-height: 100vh;
}
.loginDialog .modal-content .modal-title.h4 {
  font-size: 20px;
  color: white
}
.loginDialog .modal-content .modal-body,
.loginDialog .modal-content .modal-body .inputRow {
  font-size: 15px;
  padding-top: 0;
}

.loginDialog .modal-content .modal-body form label {
  margin: 0;
}

.loginDialog .modal-dialog {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  max-width: unset;
}

.loginDialog .modal-dialog button {
  white-space: nowrap;
  padding: 0.4em;
}

.loginDialog .modal-dialog .modal-footer {
  padding-left: calc(1rem + 20px);
  padding-right: calc(1rem + 20px);
  justify-content: center;
  font-size: 15px;
}

.loginDialog {
  background-color: #191818;
  width: 100%;
  max-height: 100vh;
  padding: 0;
  max-width: unset;
  font-family: Arial, Helvetica, sans-serif;
}

.loginDialog .modal-header,
.loginDialog .modal-footer {
  border: unset;
}

.menuItem-price.paid {
  color: green;
  text-decoration: line-through;
}

.orderrow.paid {
  background-color: khaki;
}

@media screen(max-width: 800px) {
  .loginDialog img.eatwithmetext {
    display: block;
  }
}
