.menuButtonImage {
  background-repeat: no-repeat;
  width: 190px;
  height: 190px;
  margin: 5px;
  background-position: 50% 50%;
  background-size: 100% 100% !important;
  backdrop-filter: blur(2);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.5); /* Semi-transparent white border */
  filter: drop-shadow(2px 4px 6px black) sepia(.7);
  border-radius: 10px; /* Rounded corners */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Semi-transparent white border */
  backdrop-filter: blur(10px); /* Glassy blur effect */
  transition: all 0.3s ease; /* Smooth transitions */
  
}

.menuButtonImage:hover {
  filter: drop-shadow(2px 4px 1px black) sepia(.7);
}

.menuButtonBackground {
  background: unset !important;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1;
  position: relative;
}

.menuButtonImage:before {
  content: "";
  position: absolute;
  opacity: .4;
  width: 100%;
  height: 100%;
  bottom: 0;
  margin: 5px;
  margin: 0;
  background: rgb(132, 117, 86);
  border-radius: 10px;
}

.menuButtonBackground span,
.menuButtonImage .menuicon {
  color:wheat;
  text-shadow: 1px 1px 3px darkslategray, -1px -1px 3px darkslategray, -1px 1px 3px darkslategray, 1px -1px 3px darkslategray !important;
}

.btn.menuButton {
  background: unset;
  border: undet;
  height: 100%;
  color: white;
  text-align: left;
  font-family: Mohave;
  font-size: 24px;
  letter-spacing: 0;
  text-shadow: 0px 2px 0px #00000059;
  opacity: 1;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.btn.menuButton:hover {
  color: white;
}

.menuicon {
  font-size: 35px;
}

@media (max-width: 1024px) {
  .menuButtonImage {
    width: 105px;
    height: 105px;
  }
  .btn.menuButton {
    font-size: 14px;
  }
  .menuicon {
    font-size: 21px;
  }
}
