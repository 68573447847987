#softphone .ringing:before,
#softphone .hangup:before {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0px) rotate(-135deg) ;
}

#softphone .btn:disabled {
    opacity: .2;
}

#softphone {
  z-index: 10;
}

#root > #softphone {
    width: 100%;
}
#root > #softphone > * {
  flex-grow: 1;
}

/* Define the shaking animation */
@keyframes phone-shake {
    0% {
      transform: translateY(0px) rotate(-135deg) translate(0, 0) rotate(0deg);
    }
    10% {
      transform: translateY(0px) rotate(-135deg) translate(-2px, 0px) rotate(-5deg);
    }
    20% {
      transform: translateY(0px) rotate(-135deg) translate(0px, -2px) rotate(5deg);
    }
    30% {
      transform: translateY(0px) rotate(-135deg) translate(-0px, 0px) rotate(-5deg);
    }
    40% {
      transform: translateY(0px) rotate(-135deg) translate(2px, -0px) rotate(5deg);
    }
    50% {
      transform: translateY(0px) rotate(-135deg) translate(0, 0) rotate(0deg);
    }
    100% {
      transform: translateY(0px) rotate(-135deg) translate(0, 0) rotate(0deg);
    }
  }

#softphonePlaceholder {
  margin: 0;
  padding: 0 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

#softphone .phoneringing:not(:disabled) {
   background-color: lightgreen;
}

/* Apply the animation to the phone element */
#softphone .phoneringing:not(:disabled):before {
   animation: phone-shake 0.5s infinite;
   color: green;
}
#softphone{
   display: flex;
}
#softphone .phoneringing {
    display: flex;
    line-height: 15px;
    justify-content: center;
    align-items: center;
}

#softphone .phoneringing span{
    line-height: 10px;
}

/* Apply the animation to the phone element */
#softphone .call.calling:before {
  animation: phone-shake2 0.5s infinite;
}

#softphone .call.calling {
  opacity: .5;
}

/* Define the shaking animation */
@keyframes phone-shake2 {
  0% {
    transform: translateY(0px) translate(0, 0) rotate(0deg);
  }
  10% {
    transform: translateY(0px) translate(-2px, 0px) rotate(-5deg);
  }
  20% {
    transform: translateY(0px) translate(0px, -2px) rotate(5deg);
  }
  30% {
    transform: translateY(0px) translate(-0px, 0px) rotate(-5deg);
  }
  40% {
    transform: translateY(0px) translate(2px, -0px) rotate(5deg);
  }
  50% {
    transform: translateY(0px) translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translateY(0px) translate(0, 0) rotate(0deg);
  }
}
