.customerScreen {
  background-color: black;
  height: 100%;
  display: grid;
  overflow: hidden;
  position: relative;
}

@media (orientation: landscape) {
  .customerScreen {
    flex-flow: row;
  }
  .customerScreen {
    grid-template-columns: min(50%, 70vmin) auto;
  }
  .customerScreen .ads img {
    width: auto;
    height: 100%;
  }
}

@media (orientation: portrait) {
  .customerScreen {
    flex-flow: column;
  }
  .customerScreen {
    grid-template-rows: min(50%, 100vmin) auto;
  }
  .customerScreen .ads img {
    height: auto;
    width: 100%;
  }
}

.customerScreen .ads {
  overflow: hidden;
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.customerScreen .ads .ads-img {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.customerScreen .ads .ads-img img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

.orderLevel1 {
  font-size: 2vmin;
  display: grid;
  table-layout: fixed;
  grid-template-columns: 4em auto 5em 7em;
  background-color: black;
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  grid-auto-rows: max-content;
  border-right: 1px dashed darkslategray;
}

.orderLevel1 .count,
.orderLevel1 .price,
.orderLevel1 .unitPrice,
.orderLevel1 .name {
  font-size: 1.2em;
  border-top: 1px dashed darkslategray;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
}

.orderLevel1 .price {
  border-right: unset;
}

.orderLevel1 .childName,
.orderLevel1 .name {
  white-space: pre-wrap;
}

.orderLevel1 .childName {
  grid-column-start: 2;
  grid-column-end: 3;
  padding-left: 20px;
}

.orderLevel1 .count,
.orderLevel1 .price,
.orderLevel1 .unitPrice {
  text-align: right;
}

.orderLevel1 .childPrice {
  grid-column-start: 3;
  grid-column-end: 4;
  text-align: right;
  padding-right: 5px;
}

.orderLevel1 .head {
  text-align: center;
  border-bottom: 1px solid;
}

.orderLevel1 > div {
  border-right: 1px dashed;
}

.orderLevel1 .count.footer {
  grid-column-start: 1;
  grid-column-end: 3;
  border-right: unset;
}
.orderLevel1 .price.footer {
  grid-column-start: 3;
  grid-column-end: 5;
}

.orderLevel1 .head {
  position: sticky;
  top: 0;
  left: 0;
  background: black;
}

.orderLevel1 .footer {
  position: sticky;
  bottom: 0;
  left: 0;
  background: black;
  height: 2.3em;
  font-size: 2em;
  font-weight: bold;
  line-height: 2em;
}
