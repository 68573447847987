#editMenuCategory table th,
#editMenuCategory table th div,
#editMenuItem table th,
#editMenuItem table th div {
  overflow: hidden;
}

#editMenuItem .form-group,
#editMenuCategory .form-group,
#editMenuItem .input-group,
#editMenuCategory .input-group,
#editMenuCategory .custom-control,
#editMenuItem .custom-control {
  font-size: 1rem;
}

#editMenuItem .form-group.row .input-group-text,
#editMenuCategory .form-group.row .input-group-text,
#editMenuCategory .form-group.row input,
#editMenuItem .form-group.row textarea {
  font-size: 1rem;
  height: 2.3rem;
}

select.filter {
  height: 2rem;
  border: none;
  overflow: hidden !important;
  padding: unset;
}

select.filter::-moz-focus-inner {
  border: 0;
}
select.filter:focus {
  outline: none;
}

select.filter option:not(:disabled) {
  min-width: 2rem;
  font-size: 1.2em;
  padding: .2rem 0;
  text-align: center;
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
  border-color: #10707f;
  border: 1px solid #10707f;
  border-radius: .25rem;
}

select.filter option:disabled {
  display: none;
}

select.filter option:not(:disabled):checked {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

#editMenuItem .my-1,
#editMenuItem .mb-1 {
  height: 2rem;
}

#editMenuCategory .language-prepend {
  min-width: 9rem;
}

#editMenuCategory .custom-label {
  width: 12rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 1.4rem;
}

#editMenuCategory .custom-select {
  height: 2.5rem;
  padding-top: 0rem;
  align-self: center;
}

/*
#editMenuCategory input.form-control {
  border: 1px solid #ced4da;
}*/

#editMenuCategory .tokens-container.form-control {
  font-size: 1rem;
  border-radius: 0;
  padding: 0;
}

#editMenuCategory #name-group > div#d1 {
  min-width: 30%;
}

#editMenuCategory #name-group > div#d2 {
  min-width: 70%;
}

#editMenuCategory .setting1 {
  margin-left: -1rem;
  width: calc(100% + 1rem);
}

#editMenuCategory .setting1 > * {
  margin-left: 1rem;
}

#editMenuItem .setting1.receipe,
#editMenuCategory .setting1.receipe {
  background-color: lightgray;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
}

td.name {
  min-width: 15rem;
  font-weight: normal;
  font-size: 1.2rem;
}

td.description {
  min-width: 2rem;
  font-weight: normal;
  font-size: 1.2rem;
}

td.description input {
  width: -webkit-fill-available;
}

td.timing {
  min-width: 4rem;
}

td.picture {
  min-width: 3rem;
  display: table-cell;
}

td.picture img {
  height: 3rem;
  width: 3rem;
}

td.availablequantity1 {
  min-width: 6rem;
  overflow: hidden;
}

td.unitPrice,
td.availablequantity2 {
  min-width: 3rem;
  overflow: hidden;
}

td.productionline {
  overflow: hidden;
  min-width: 7rem;
}

.menuItems td.warning {
  background: red;
  color: white;
}
.menuItems td.warning span {
  color: white;
}

/*
body:not([device = "browser"]) #meals #menuItemsFreezeTable,
body:not([device = "browser"]) #drinks #menuItemsFreezeTable {
  overflow: auto !important;
  padding-left: 25px;
  padding-right: 40px;
}*/

/*
#meals #menuItemsFreezeTable:before,
#drinks #menuItemsFreezeTable:before {
  content: "\e83e";
  font-family: "Fontello";
  position: absolute;
  right: 10px;
  top: calc(50% - 50px);
  line-height: 0;
  font-size: 20px;
  height: 50px;
  background-color: #00ced152;
  color: #00ced152;
}

#meals #menuItemsFreezeTable:after,
#drinks #menuItemsFreezeTable:after {
  content: "\e840";
  font-family: "Fontello";
  position: absolute;
  right: 10px;
  top: calc(50% + 0px);
  line-height: 103px;
  font-size: 20px;
  height: 50px;
  background-color: #00ced152;
  color: #00ced152;
}*/

#meals td.type .referred,
#drinks td.type .referred {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: .6rem !important;
  width: 10rem;
  white-space: normal;
}

/*
#menuItemsFreezeTable table {
    margin-left:50px;
}*/

#menuItemsFreezeTable > table tr,
#menuItemsFreezeTable > table {
  outline: unset;
}

td.availablequantity input[type="text"],
td input[type="number"] {
  text-align: right;
  width: -webkit-fill-available;
  padding-right: 2px;
  max-width: 4rem;
}

div#availableQuantities input[type="number"] {
  max-width: 9rem;
}

td span {
  padding-right: 2px;
}

td input::-webkit-outer-spin-button,
td input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

td select {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  min-width: 0;
  text-align: right;
}

td select::-ms-expand {
  display: none !important;
}

td input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.custom-label {
  width: 12rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 1rem;
  flex-shrink: 0;
}

form > .input-group {
  flex-wrap: nowrap;
}

.custom-select {
  height: 2.5rem;
  padding-top: 0.2rem;
}

input.form-control {
  /*border: 1px solid #ced4da;*/
}

#main #restaurantDropdown button {
  font-size: 1rem;
}

.tokens-container.form-control {
  font-size: 2rem;
  border-radius: 0;
  padding: 0;
}

.language-prepend {
  min-width: 200px;
}

#editMenuCategory .token span,
#editMenuItem .token span {
  display: flex;
  white-space: nowrap;
}

.tokenize-dropdown input {
  display: none;
}

.tokenize-dropdown.tokenize-dropdown .dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
}

#editMenuCategory .token span input,
#editMenuItem .token span input {
  width: unset;
  min-width: 2rem;
  max-width: 5rem;
  margin: 0 5px;
  padding: 0;
  text-align: right;
}

#editMenuCategory .token span > span.quantity,
#editMenuItem .token span > span.quantity {
  display: none;
}


#editMenuCategory #references ~ .tokenize .token span > span.quantity,
#editMenuItem #references ~ .tokenize .token span > span.quantity {
  display: block;
}

#editMenuItem .token span.takeaway,
#editMenuItem .token span.local,
#editMenuCategory .token span.takeaway,
#editMenuCategory .token span.local {
  background:rgb(141 122 122 / 26%)
}

#editMenuItem .token span.takeaway input,
#editMenuItem .token span.local input,
#editMenuCategory .token span.takeaway input,
#editMenuCategory .token span.local input {
  accent-color: blue;
  margin: 0;
}

#editMenuItem .token span.proportional,
#editMenuItem .token span.takeaway,
#editMenuCategory .token span.proportional,
#editMenuCategory .token span.takeaway {
  border-right: 1px solid lightgray;
}

#editMenuItem .token span.local,
#editMenuCategory .token span.local {
  border-left: 1px solid lightgray;
  margin:0;
  margin-left: 10px;
}

.custom-control.custom-checkbox {
  padding-right: 1rem;
}

#editMenuItem .tokenize > .tokens-container:not(.disabled),
#editMenuCategory .tokenize > .tokens-container:not(.disabled) {
  background-color:white;
}


#editMenuItem .tokenize > .tokens-container.disabled li,
#editMenuCategory .tokenize > .tokens-container.disabled li {
  font-size: 1rem;
  color: gray;
}

#editMenuItem .tokenize > .tokens-container.disabled li input,
#editMenuCategory .tokenize > .tokens-container.disabled li input {
  pointer-events: none;
}

.custom-radio.compulsory .custom-control-input:checked ~ .custom-control-label::before {
  background-color: red;
}

tr.fancytree-match span.fancytree-title {
  flex-grow: 1;
}

i.fa.fa-calendar {
  background-image: url("../img/calendar-icon-48.png");
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.ui-resizable-n {
  cursor: s-resize;
  height: 2px;
  top: 0;
  left: 0;
  width: 100%;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: blue;
  opacity: 0.2;
  /* Firefox */
}

.floatThead-container {
  flex-shrink: 0;
}

td.picture {
  justify-content: center;
  height: 100%;
  display: table-cell;
}

td.name span.manufacturer {
  position: sticky;
  position: -webkit-sticky;
  left: 100%;
  top: 0;
  font-weight: lighter;
  font-size: 1rem;
  text-shadow: 2px 2px 2px white, -2px -2px 2px white, 2px -2px 2px white, 2px -2px 2px white;
  height: 3rem;
  max-width: 7rem;
  white-space: pre-wrap;
  word-break: break-word;
  display: block;
  text-align: right;
}


.custom-checkbox .custom-control-input.parentUnchecked:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: gray;
}

#meals .input-group > div,
#drinks .input-group > div {
  margin-left: 1rem;
  flex-basis: 10%;
}

#meals .input-group,
#drinks .input-group {
  margin-left: -1rem;
  margin-top: 1px;
  margin-bottom: 1px;
}

#meals .input-group .input-group-text,
#drinks .input-group  .input-group-text{
  min-width: 13rem;
}

#drinks .missing-image label:before,
#meals .missing-image label:before {
  background-color: red;
}

#drinks #daterange,
#meals #daterange {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 20em;
}

#editMenuItem .icon-cup {
  line-height: 0;
}

#editMenuItem .icon-cup:before {
  font-size: 1rem !important;
}

#drinks #daterange i.fa.fa-calendar {
  font-family: "FontAwesome";
  background-image: unset;
  width: 2rem;
}

.range label {
  height: 2rem;
  line-height: 1rem;
}

@media (max-height: 800px) {
  .btn-group .btn {
  }
}

.btn-toolbar .btn-group button {
  padding: 8px;
  border-radius: 4px !important;
  margin-right: 2px !important;
}

.btn-toolbar .btn-group {
  margin-left: 10px;
  font-size: 14px;
}

.btn-toolbar .btn-group:nth-child(1) {
  margin-left: 0;
}

#editMenuItem .language-prepend {
  min-width: 9rem;
}

#editMenuItem .custom-label {
  width: 12rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 1.5rem;
}

#editMenuItem .custom-select {
  height: 2rem;
  padding-top: 0rem;
  font-size: 1rem;
}

#editMenuItem input.form-control {
  border-bottom: 1px solid #ced4da;
}

#editMenuItem .tokens-container.form-control {
  font-size: 1rem;
  border-radius: 0;
  padding: 0;
}

textarea.form-control {
  height: 2.3rem;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

#editMenuCategory .modal-footer,
#editMenuItem .modal-footer {
  font-size: 22px;
  height: 64px;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  border-top: 4px solid gray;
  background-color: white;
}

#editMenuCategory .modal-header,
#editMenuItem .modal-header {
  display: flex;
  flex-flow: row;
  flex-shrink: 0;
  padding-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 4px solid gray;
}

#editMenuItem #next-item {
  height: 100%;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d3d3d366;
}
#editMenuItem #previous-item {
  height: 100%;
  width: 48px;
  margin-right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d3d3d366;
}

#editMenuCategory .modal-body,
#editMenuItem .modal-body {
  background-color: #ededed;
}

#editMenuCategory .modal-footer button,
#editMenuItem .modal-footer button {
  min-width: 59px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 4px;
}

@media (min-width: 801px) {
  .mobile-only {
    display: none !important;
  }
}

#editMenuItem table th {
  white-space: nowrap;
  font-size: 1rem;
}

#smallImageFalatozz,
#smallImageWolt,
#smallImageFoodpanda {
  height: auto;
  max-width: 110px;
  max-height: 160px;
  box-shadow: 0 0 1px gray;
}

#smallImage {
  width: 160px;
  height: auto;
  max-width: 160px;
  box-shadow: 0 0 1px gray;
}

#smallImage[src=""] ~ .icon-trash-empty {
  display: none !important;
}

td.name span.fancytree-title {
  padding: 0;
}

.tokenize > .tokens-container > .token {
  display: inline-flex;
  align-items: center;
}

#editMenuItem .token .custom-control,
#editMenuCategory .token .custom-control {
  height: 1rem;
}

#menuItemsFreezeTable thead {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  top: 0;
  background-color: white;
}

.modal:not(.inventory) table#menuItemQuantities th.menuItem_cost,
.modal:not(.inventory) table#menuItemQuantities td.menuItem_cost {
  display: none;
}

table#menuItems tbody > tr.menu-category > td.name span.fancytree-node span.fancytree-title {
  font-weight: 800;
}

table#menuItems tbody > tr > td.name span.fancytree-node span.fancytree-title {
  padding-left: 0.5rem;
}

table#menuItems tbody tr.fancytree-has-children td.name span.fancytree-node .fancytree-expander:before {
  content: "\e833";
  font-family: "Fontello";
  color: gray;
}
table#menuItems tbody tr td.name span.fancytree-node .fancytree-expander {
  background-image: unset;
  font-size: 0.7rem;
  padding-top: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 1rem;
}
table#menuItems tbody tr.fancytree-expanded td.name span.fancytree-node .fancytree-expander:before {
  content: "\e835";
  font-family: "Fontello";
}

table.fancytree-ext-table tbody tr.fancytree-focused {
  background-color: inherit;
}
table.fancytree-ext-table tbody tr.fancytree-active {
  background-color: #cbe8f6;
}

#editMenuItem #menuItemQuantities td.pricetd > div {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
#editMenuItem #menuItemQuantities td.pricetd > div > label {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 1.5em;
  margin: 0;
}
#editMenuCategory .modal-header .custom-checkbox {
  display: flex;
  align-items: normal;
  justify-content: center;
}
#editMenuCategory .modal-header .custom-control-label {
  display: flex;
  align-items: center;
  width: unset;
}

/*
#editMenuCategory .modal-header .custom-control-label::before,
#editMenuCategory .modal-header .custom-control-label::after {
  top: 0.6rem;
}
*/

#editMenuItem .modal-header .datetimepicker,
#editMenuCategory .modal-header .datetimepicker {
  margin-left: 20px;
}

#editMenuItem .modal-header .gj-datepicker,
#editMenuCategory .modal-header .gj-datepicker {
  margin-left: 10px;
  min-width: 8rem;
}

#editMenuItem .modal-header .setting1 >*,
#editMenuCategory .modal-header .setting1 >* {
  margin-top: .3rem;
}
#editMenuItem .modal-header .gj-datepicker .gj-icon,
#editMenuItem .modal-header .gj-datepicker button,
#editMenuCategory .modal-header .gj-datepicker .gj-icon,
#editMenuCategory .modal-header .gj-datepicker button {
  height: 1.4rem;
  padding: 0;
}

#editMenuItem .modal-header input.datepicker,
#editMenuCategory .modal-header input.datepicker {
  height: 1.4rem;
  padding: 0;
  width: 5rem;
}

#menuItemsFreezeTable th {
  line-height: 1rem;
  font-size: .7rem;
}

#menuItemsFreezeTable th > div {
  display: flex;
  flex-flow: column;
  align-items: center;
}

#menuItemsFreezeTable th .icon-wolt,
#menuItemsFreezeTable th .icon-foodpanda,
#menuItemsFreezeTable th .icon-falatozz,
#editMenuCategory .modal-header .icon-wolt,
#editMenuCategory .modal-header .icon-foodpanda,
#editMenuCategory .modal-header .icon-falatozz,
#editMenuItem #menuItemQuantities td.pricetd > div .icon-wolt,
#editMenuItem #menuItemQuantities td.pricetd > div .icon-foodpanda,
#editMenuItem #menuItemQuantities td.pricetd > div .icon-falatozz {
  width: 1rem;
  height: 1rem;
  margin-right: 0.4rem;
  white-space: nowrap;
}

#menuItemsFreezeTable .preorder {
  font-size: .8rem;
}

/*
#editMenuItem #menuItemQuantities th:nth-child(1),
#editMenuItem #menuItemQuantities td:nth-child(1) {
  display: none;
}*/

@media screen and (max-width: 970px) {
  .selectTableOccupation .modal-header #zoomlevel2,
  .selectTableOccupation .modal-header .btn {  
    font-size: .55rem !important;
    line-height: .55rem !important;
    padding: .3rem !important;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
    white-space: nowrap;
  }

  
}


@media screen and (max-width: 800px) {
  #drinks .btn-toolbar,
  #meals .btn-toolbar {
    font-size: 2rem !important;
  }

  .browser-only {
    display: none !important;
  }

  th.description,
  td.description,
  th.picture,
  td.picture,
  th.timing,
  td.timing,
  th.productionline,
  td.productionline,
  th.manufacturer,
  td.manufacturer,
  th.vat,
  td.vat,
  th.inventory,
  td.inventory,
  th.cost,
  td.cost,
  th.quantitytype,
  td.quantitytype,
  th.labels,
  th.ntak,
  td.thirdparty,
  td.ntak,
  td.preorder,
  td.pricePerCostRatio,
  th.pricePerCostRatio,
  td.unitprice,
  td.labels {
    display: none;
  }

  td.name {
    font-size: 2rem;
    position: relative;
  }

  td.name > img {
    height: 14rem;
    width: 14rem;
    opacity: 0.9;
    border-radius: 0.6rem;
    margin-left: -0.3rem;
    margin-top: -0.2rem;
  }

  tr.itemDeactivated td.name > img {
    opacity: 0.3;
  }

  td.name > img ~ span {
    position: absolute;
    bottom: 0;
    top: 0;
  }

  td.name > img ~ span > span.fancytree-title {
    position: absolute;
    bottom: 0;
    text-shadow: 2px 2px 4px white, -2px -2px 4px white;
    width: 100%;
  }

  td.name span.fancytree-node {
    display: block;
  }

  td.name > img ~ span > span.fancytree-expander {
    background-image: unset;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    transform: unset;
  }

  #editMenuCategory .form-group input,
  #editMenuItem .form-group input {
    min-width: 11rem;
  }

  #editMenuCategory .form-group.row .language-prepend,
  #editMenuItem .form-group.row .language-prepend {
    min-width: unset;
  }

  #editMenuItem #availableQuantities,
  #editMenuCategory .form-group.row .language-prepend .input-group-text #editMenuItem .form-group.row .language-prepend .input-group-text {
    font-size: 1rem;
  }

  .fancytree-exp-nl span.fancytree-expander,
  .fancytree-exp-n span.fancytree-expander {
    display: none;
  }

  td.availablequantity1 {
    min-width: 14rem;
    overflow: hidden;
  }

  table.fancytree-ext-table tbody tr:not(.fancytree-has-children) {
    border: 1px dashed !important;
  }

  table.fancytree-ext-table tbody tr[level="1"] {
    background: rgb(220, 248, 204);
  }

  table.fancytree-ext-table tbody tr[level="2"] {
    background: rgb(250, 248, 214);
  }

  table.fancytree-ext-table tbody tr[level="3"] {
    background: rgb(250, 248, 224);
  }

  table.fancytree-ext-table tbody tr[level="4"] {
    background: rgb(250, 248, 234);
  }

  table#menuItems tbody tr.fancytree-expanded {
    border-color: darkslategray;
  }

  table#menuItems tbody tr[level="1"] {
    position: sticky;
    position: -webkit-sticky;
    top: 1rem;
    left: 0;
    z-index: 10;
  }

  table#menuItems tbody tr[level="2"] {
    position: sticky;
    position: -webkit-sticky;
    top: 9rem;
    left: 0;
    z-index: 9;
  }

  table#menuItems tbody tr[level="3"] {
    position: sticky;
    position: -webkit-sticky;
    top: 33rem;
    left: 0;
    z-index: 9;
  }

  table#menuItems tbody td {
    margin: 2px;
  }

  table.fancytree-ext-table {
    font-size: 2rem;
  }

  .clone-head-table-wrap .menuItems,
  #menuItemQuantities thead,
  .menuItems thead {
    display: none;
  }

  #menuItems tbody tr:not(.fancytree-hide) {
    display: grid;
    grid-template-columns: 0rem 1fr 1fr 1fr 1fr;
    grid-template-rows: 3rem;
    margin: 10px;
    border-radius: 10px;
    /*        
        padding-left: 12rem;
        position: relative;
        */
    min-height: 7rem;
    max-height: 25rem;
    overflow: hidden;
  }

  #menuItems tbody tr.fancytree-has-children:not(.fancytree-hide) {
    grid-template-columns: 17rem 1fr 1fr 1fr 1fr;
    min-height: 4rem;
    max-height: 25rem;
  }

  #menuItems tbody tr:not(.fancytree-hide).fancytree-active.fancytree-focused {
    max-height: unset;
  }

  #menuItems tbody tr td {
    border: unset;
    margin-top: 0rem;
    max-width: unset !important;
    min-width: unset !important;
    grid-row-start: 2;
  }

  #menuItems .fancytree-node {
    padding-left: 0 !important;
  }

  .menuItems tbody tr td.inventory,
  .menuItems tbody tr td.active,
  .menuItems tbody tr td.top,
  .menuItems tbody tr td.online {
  }

  #menuItemQuantities tbody tr:not(.fancytree-hide) {
    display: flex;
    flex-flow: row;
    margin: 10px 0;
    border-radius: 10px;
    flex-wrap: wrap;
    position: relative;
  }

  #menuItemQuantities tbody tr td {
    display: block;
    min-width: 3rem;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  table.fancytree-ext-table tbody tr.fancytree-focused span.fancytree-title {
    outline: unset;
  }

  table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-active {
    outline: unset;
  }

  table#menuItems td.availablequantity1 {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  table#menuItems.fancytree-ext-table {
    font-size: 2em !important;
  }

  div#menuItems {
    overflow-y: auto !important;
  }

  table#menuItems {
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  table#menuItems tbody td.name {
    margin-top: 0;
    grid-row-start: 1;
    grid-row-end: 3;
    /*
        min-width: 9rem;
        max-width: 9rem;
        height: 8.8rem;
        margin-left: -12rem;
        */
    padding-top: 0;
  }

  table#menuItems tbody td.name i {
    display: none;
  }

  table#menuItems tbody td.quantity {
    margin-top: 0;
    min-width: 9rem;
    max-width: 9rem;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 5;
    grid-column-end: 6;
    width: 100%;
    position: absolute;
    height: 8.8rem;
    right: 0;
    padding-top: 0;
    font-weight: bold;
    height: 3rem;
    text-align: left !important;
    padding-left: 4rem;
  }

  table#menuItems tbody tr.fancytree-has-children td.total-store,
  table#menuItems tbody tr.fancytree-has-children td.store {
    display: none;
  }

  table#menuItems tbody td.total-store,
  table#menuItems tbody td.store {
    min-width: 4rem;
    background: transparent !important;
  }

  table#menuItems tbody td.total-store > div,
  table#menuItems tbody td.store > div {
    display: flex;
    flex-flow: row;
    justify-content: center;
  }

  #StockList table#menuItems tbody td.active {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 4rem;
  }
  #StockList table#menuItems tbody td.isTop {
    position: absolute;
    right: 0;
    top: 3rem;
    max-width: 4rem;
  }

  table#menuItems tbody td.name span.fancytree-node {
    position: absolute;
    top: 0;
    white-space: nowrap;
    height: 100%;
  }
  table#menuItems tbody td.name span.fancytree-node .fancytree-expander {
    position: absolute;
    width: unset;
    height: unset;
    font-size: 4rem;
    background-image: unset;
    padding: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  table#menuItems tbody tr td.name span.fancytree-node .fancytree-expander {
    font-size: 4rem;
  }
  table#menuItems tbody tr.fancytree-expanded td.name span.fancytree-node .fancytree-expander {
    margin-top: -0.5rem;
  }

  table#menuItems tbody tr td.name img + span.fancytree-node .fancytree-expander:before {
    color: transparent !important;
  }

  table#menuItems tbody td.name span.fancytree-node .fancytree-title {
    position: absolute;
    left: calc(100% + 3rem);
    top: 0;
    font-weight: bold;
  }

  .btn-toolbar .btn-group .btn {
    padding: 0.5rem;
  }

  .custom-checkbox input#mi_active.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mi_isTop.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mi_online.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mi_available.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mi_isInventoryItem.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mc_active.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mc_isTop.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mc_online.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mc_available.custom-control-input.checkbox ~ .custom-control-label,
  .custom-checkbox input#mc_isInventoryItem.custom-control-input.checkbox ~ .custom-control-label {
    font-size: 0;
  }

  .custom-checkbox input#mc_active.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_isTop.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_online.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_isInventoryItem.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mi_active.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mi_isTop.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mi_online.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mi_isInventoryItem.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.onMenu ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.inventoryItem ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.online ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.available ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.isTop ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.isActive ~ .custom-control-label::after {
    background-image: unset;
    text-align: center;
    margin-top: -0.4rem;
    color: rgb(141, 122, 122);
    font-size: 2rem;
    width: 2.2rem;
  }

  .custom-checkbox input.custom-control-input.checkbox ~ .custom-control-label::before {
    width: 2.2rem;
    height: 2.2rem;
  }

  .custom-checkbox input.custom-control-input.checkbox ~ .custom-control-label {
    padding-left: 1rem;
  }

  .custom-checkbox input#mi_online.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_online.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.online ~ .custom-control-label::after {
    content: "O";
  }

  .custom-checkbox input#mi_available.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_available.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.available ~ .custom-control-label::after {
    content: "R";
  }

  .custom-checkbox input#mi_isTop.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_isTop.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.onMenu ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.isTop ~ .custom-control-label::after {
    content: "M";
  }

  .custom-checkbox input#mi_active.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_active.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.isActive ~ .custom-control-label::after {
    content: "A";
  }

  .custom-checkbox input#mi_isInventoryItem.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input#mc_isInventoryItem.custom-control-input.checkbox ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.inventoryItem ~ .custom-control-label::after {
    content: "I";
  }

  .custom-checkbox input#mi_active.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mi_isTop.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mi_online.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mi_availale.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mi_isInventoryItem.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mc_active.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mc_isTop.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mc_online.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mc_available.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input#mc_isInventoryItem.custom-control-input.checkbox:checked ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.onMenu:checked ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.inventoryItem:checked ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.online:checked ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.available:checked ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.isTop:checked ~ .custom-control-label::after,
  .custom-checkbox input.custom-control-input.checkbox.isActive:checked ~ .custom-control-label::after {
    color: white;
  }

  #editMenuCategory .custom-label {
    width: 6rem;
  }

  #chart-block .chart-bar {
    flex-flow: column;
  }
  #chart-block .chart-bar #daterange {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  #chart-block #myChart {
    flex-shrink: 1;
    padding-bottom: 4rem !important;
  }
}

@media (min-width: 600px) and(max-width: 800px) {
  .menuItemHeader.mealsheader .right-box,
  .menuItemHeader.drinksheader .right-box,
  .menuItemHeader.mealsheader .meals-index-title,
  .menuItemHeader.drinksheader .drinks-index-title {
    display: none !important;
  }

  #meals .btn-toolbar,
  #meals .btn-toolbar {
    flex-wrap: nowrap;
    position: fixed;
    top: 12px;
    right: 80px;
    left: 30px;
    font-size: 5px;
    height: 33px;
  }
  #meals .btn-toolbar #showDeleted,
  #meals .btn-toolbar #showDeleted {
    display: none !important;
  }

  body.test-mode .btn-toolbar {
    top: 22px;
  }

  .btn-toolbar .btn-group .btn {
    margin: 2px;
    padding: 2px;
    font-size: 16px;
    height: 29px;
  }

  #restaurantDropdown {
    max-width: 70px;
  }

  #restaurantDropdown button {
    padding: 0.05rem;
    text-align: center;
    width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  #restaurantDropdown button.dropdown-toggle::after {
    position: absolute;
    right: 0;
  }
}
#drinks .icon-check:disabled,
#drinks .icon-left:disabled,
#drinks .icon-right:disabled,
#meals .icon-check:disabled,
#meals .icon-left:disabled,
#meals .icon-right:disabled {
  color: lightgray
}