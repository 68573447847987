.daterangepicker.ltr.show-ranges .drp-buttons .drp-selected{
    display:none
  }
  
  @media (max-height: 900px) {
  
  .daterangepicker.ltr.show-ranges {
    position: fixed !important;
    top: 50% !important;
    left:50% !important;
    transform: translate(-50%, -50%);
    overflow-y:auto;
    max-height: calc(100vh - 4rem);
  }
  
  .daterangepicker.ltr.show-ranges .drp-buttons {
    position: sticky;
    bottom:0;
    left:0;
    background: white;
  }
  
  }