#guestScreen svg {
    background-color: white;
}



#guestScreen circle {
    transition: fill 2s ease, r 2s ease;
    filter: url(#shadow)
}

#guestScreen g {
    transition: transform 2s ease;
}

#guestScreen text {
    font-family: Arial, sans-serif;
    fill: black;
    font-size: 12px;
    dominant-baseline: middle;
    text-anchor: middle;
    transition: font-size 2s ease, y 2s ease;
}

#guestScreen .delivering {
    overflow: hidden;
    height: 100%;
}

#guestScreen .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 60px;
    background-color: transparent;
    color: white;
    font-weight: 800;
    width: 100%;
    text-align: center;
    border-bottom: 3px solid white;
    background: rgba(128, 128, 128, 0.177);

}