#main {
  height: 100%;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

#main .modal-footer {
  flex-shrink: 0;
}

.bar #back {
  display: none;
}
.bar .mytitle {
  display: none;
}
#main #floorplan {
  background: rgb(250, 248, 234);
}
#main.overview .bar {
  background: rgb(250, 248, 234);
}

.bar .user-img {
  display: none;
}

.paying-customer.customer > div.persons-stack {
  height: unset;
}

#tableMatePaidDialog .state-icon,
#markTableMateForPaymentDialog .state-icon,
#orderMenuItem .state-icon {
  display: none;
}

.clone-head-table-wrap:not(.clone-column-head-table-wrap) {
  overflow-y: visible !important;
}

#floorplan {
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
  position: relative;
  right: 0;
  left: 0;
  overflow: auto;
  margin-top: 0;
  flex-grow: 1;
  border-radius: 10px;
  background: white;
  overflow: hidden;
}

#floorplan-main iframe {
  border: unset;
}

#floorplan-main #c {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23dddbe1' fill-opacity='.2'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
}

#floorplan-main #c > div {
  display: block;
  flex-grow: 1;
  height: 100%;
}

/*****************************************************************/
/*orientation="landscape" :not(.room)*/

/* aspect = 1 */

#floorplan[orientation="landscape"][aspect="1"]:not(.room) {
  flex-flow: row;
}
#floorplan[orientation="landscape"][aspect="1"]:not(.room) #c div svg {
  /*
  height: 100%;
  left: 50%;
  transform: translateX(-50%) !important;
  */
  height:100%;
  width: 100%;
}
/* aspect = 2 */
#floorplan[orientation="landscape"][aspect="2"]:not(.room) #c {
  height: 100%;
  width: 100%;
}
#floorplan[orientation="landscape"][aspect="2"]:not(.room) #c > div {
  display: flex;
  justify-items: center;
  align-items: center;
}
#floorplan[orientation="landscape"][aspect="2"]:not(.room) #c div svg {
  width: 100%;
}

/* aspect = 3 */

/* aspect = 4 */

/*****************************************************************/
/*orientation="landscape" .room*/

/* aspect = 1 */
#floorplan.room[orientation="landscape"][aspect="1"] #c > div {
  display: flex;
  justify-content: center;
}
#floorplan.room[orientation="landscape"][aspect="1"] #c div svg {
  height: 100%;
}

/* aspect = 2 */
#floorplan.room[orientation="landscape"][aspect="2"] #c {
  width: 100%;
}
#floorplan.room[orientation="landscape"][aspect="2"] #c > div {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
#floorplan.room[orientation="landscape"][aspect="2"] #c div svg {
  width: 100%;
}

/* aspect = 3 */

/* aspect = 4 */

/*****************************************************************/
/*orientation="portait" :not(.room)*/

/* aspect = 1 */
#floorplan[orientation="portrait"][aspect="1"]:not(.room) #c div svg {
  height: 100%;
}
#floorplan[orientation="portrait"][aspect="1"]:not(.room) #c > div {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
/* aspect = 2 */
#floorplan[orientation="portrait"][aspect="2"]:not(.room) #c {
  width: 100%;
}
#floorplan[orientation="portrait"][aspect="2"]:not(.room) #c > div {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
#floorplan[orientation="portrait"][aspect="2"]:not(.room) #c svg {
  width: 100%;
  height: unset;
}

/*****************************************************************/
/*orientation="portait" .room*/

/* aspect = 1 */
#floorplan.room[orientation="portrait"][aspect="1"] {
  flex-flow: row;
}
#floorplan.room[orientation="portrait"][aspect="1"][variant="1"] #c {
  height: 100%;
}
#floorplan.room[orientation="portrait"][aspect="1"][variant="2"] #c {
  height: unset;
}
#floorplan.room[orientation="portrait"][aspect="1"] #c > div {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* aspect = 2 */
#floorplan.room[orientation="portrait"][aspect="2"] {
  flex-flow: row;
}
/*terasz, dohányzó, nagyterem*/
#floorplan.room[orientation="portrait"][aspect="2"][variant="1"] #c {
  height: 100%;
}
/*kisterem*/
#floorplan.room[orientation="portrait"][aspect="2"][variant="2"] #c {
  height: unset;
}
#floorplan.room[orientation="portrait"][aspect="2"] #c > div {
  display: flex;
  justify-content: center;
}
/*terasz, dohányzó, nagyterem*/
#floorplan.room[orientation="portrait"][aspect="2"] #c > div svg {
  width: 100%;
  height: 100%;
}

/*****************************************************************/

/*
#floorplan.room[aspect="1"] #c > div:nth-child(1) {
  height: 100%;
}

#floorplan.room[orientation="portrait"][aspect="1"] #c {
  height: unset !important;
  top: unset !important;
  transform: translate(-50%, 0) !important;
}

#c > div:nth-child(2) {
  position: relative;
  height: 100%;
  width: 100%;
}
*/

.menuItemHeader .eatwithmetext {
  margin-right: 20px;
}

.menuItemHeader .title {
  font-size: 1em;
  text-align: center;
}

@media screen and (max-width: 800px) {
  img.eatwithmetext {
    display: none;
  }
}

.menuItemHeader {
  flex-shrink: 0;
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 30px;
  color: rgb(131, 123, 108);
}

.menuItemHeader-1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 50px;
  align-items: center;
  font-size: 18px;
  background-color: #FAF8EA;
  color: #837B6C;
  padding-right: 30px
}

.menuItemHeader *[class*="i_"]:before,
.menuItemHeader *[class^="icon-"]:before,
.menuItemHeader *[class*=" icon-"]:before {
  font-size: 30px;
}

.menuItemHeader .icon-help {
  color: darkslategray;
}

.menuItemHeader .icon-print-1:before,
.menuItemHeader .icon-cog-outline:before {
  font-size: 23px;
  color: darkslategray;
}

.menuItemHeader *[class*="i_"]:before {
  font-size: 27px;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .menuItemHeader {
    padding-left: 10px;
    padding-right: 10px;
  }
  .menuItemHeader .eatwithmetext,
  .menuItemHeader .myTitle {
    display: none !important;
  }
}
.overview .bar #logout,
.overview .bar #login {
  display: none;
}

.jquery-loading-modal__info-box {
  top: 50%;
  left: 50%;
  width: unset;
  transform: translateX(-50%);
}

.modal .modal-footer {
  font-size: 20px;
}

.modal-footer > * {
  margin: 3px; 
}


.order-table-box .orderCategoryImage,
.order-table-box-outer .orderCategoryImage {
  display: none;
}

table.fancytree-ext-table {
  font-family: "Mohave";
}

.react-grid-checkbox-container {
  width: 40px !important;
}

.react-grid-table-container {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  overflow-y: auto !important;
  display: block;
  width: fit-content;
  height: calc(100% - 32px);
}

.react-grid-header th .react-grid-column {
  transform: scaleX(0.5) translateX(-47%);
  overflow: visible !important;
  width: unset !important;
  line-height: 0 !important;
  height: 0 !important;
  top: 0 !important;
}

.react-grid-container {
  height: 100%;
  overflow-y: hidden;
}

.splitter-layout .layout-pane {
  overflow: hidden;
}

i.fa.fa-calendar {
  background-image: unset;
  font-family: "FontAwesome";
  width: 1em;
  height: 1em;
}

.clone-head-table-wrap tfoot {
  display: none !important;
}

.fancytree-container thead {
  background: white;
}

.fancytree-ext-table {
  font-size: 1em !important;
}

.clone-head-table-wrap > table {
  width: 100%;
}

table {
  outline: unset;
}

table.fancytree-container th,
table.fancytree-container td {
  padding: 2px;
}

.select2:not(.select2-container--open),
.select2-container--focus {
  overflow: hidden;
}

table th {
  text-align: center;
}

.fc-timeGrid-view .fc-day-grid .fc-row {
  max-height: 40vh;
  overflow-y: overlay;
}

.fc .fc-row .fc-content-skeleton td {
  border-color: #ddd;
}

.fc-timeline-event.fc-event {
  height: fit-content !important;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  min-width: 1em;
  width: unset;
}

.introjs-tooltiptext {
  max-height: 70vh;
  overflow-y: auto;
}

.introjs-helperLayer {
  z-index: 10000000;
  background: 0;
  border-width: 2px;
  border-color: yellow;
}

.eatwithme-table rect {
  stroke-width: 0.8;
}

.eatwithme-seat path {
  stroke-width: 0.5;
}

.a_bottomright {
  position: relative;
}

.a_bottomright:after {
  font-size: 0.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}

#fancytree-drop-marker {
  background-image: unset;
}

#fancytree-drop-marker:before {
  content: "\f178";
  font-family: "Fontello";
  font-size: 20px;
  top: -5px;
  position: relative;
  text-shadow: 0 0 2px white;
}

#fancytree-drop-marker.fancytree-drop-after:before {
  content: "\f178";
  transform: rotate(30deg);
  display: inline-block;
}
#fancytree-drop-marker.fancytree-drop-before:before {
  content: "\f178";
  transform: rotate(-30deg);
  display: inline-block;
}
